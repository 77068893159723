import React, { memo } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { generateExternalUrl } from '@paysera/external-router';

import styles from './FullPageFooter.module.scss';

const FullPageFooter = () => {
    const { t } = useTranslation('checkout-frontend');

    const activeLanguage = i18next.language;
    const privacyPolicyUrl = generateExternalUrl('paysera_frontend_privacy_policy', activeLanguage);
    const payseraContactsUrl = generateExternalUrl('paysera_frontend_contacts', activeLanguage);
    const payseraHomepageUrl = generateExternalUrl('paysera_frontend_homepage', activeLanguage);
    const lbPayseraLicenceUrl = generateExternalUrl('lb_paysera_license', activeLanguage);
    const allRightsReservedLink = {
        __html: t(
            'all_rights_reserved',
            {
                rightsLink:
                    `<a target="_blank"
                        rel="noopener noreferrer"
                        href=${payseraHomepageUrl}
                     >${t('all_rights_reserved_link_text')}</a>`,
            },
        ),
    };
    const moneyInstitutionLink = {
        __html: t(
            'e_money_institution_is_supervised_by',
            {
                eMoneyInstitutionLink:
                    `<a target="_blank"
                        rel="noopener noreferrer"
                        href=${lbPayseraLicenceUrl}
                     >${t('e_money_institution_is_supervised_by_link_text')}</a>`,
            },
        ),
    };

    return (
        <footer className={styles.footer}>
            <div className="container">
                <div className="row justify-content-between">
                    <div className={`col-md-4 ${styles.leftBlock}`}>
                        <span>
                            <a target="_blank" rel="noopener noreferrer" href={privacyPolicyUrl}>
                                {t('privacy_policy')}
                            </a>
                        </span>
                        <span>
                            <a target="_blank" rel="noopener noreferrer" href={payseraContactsUrl}>
                                {t('contacts')}
                            </a>
                        </span>
                    </div>

                    <div className={`col-md-7 ${styles.rightBlock}`}>
                        <div dangerouslySetInnerHTML={allRightsReservedLink} />
                        <div dangerouslySetInnerHTML={moneyInstitutionLink} />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default memo(FullPageFooter);
