import { Entity } from '@paysera/http-client-common';

class CheckupComment extends Entity {
    constructor(data = {}) {
        super(data);
    }

    /**
     * @return {Number|null}
     */
    getCreatedAt() {
        return this.get('created_at');
    }

    /**
     * @param {Number} createdAt
     */
    setCreatedAt(createdAt) {
        this.set('created_at', createdAt);
    }

    /**
     * @return {string}
     */
    getComment() {
        return this.get('comment');
    }

    /**
     * @param {string} comment
     */
    setComment(comment) {
        this.set('comment', comment);
    }
}

export default CheckupComment;
