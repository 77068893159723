import { captureException } from '@sentry/browser';
import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';

import DebounceLoader from '../../../DebounceLoader';
import cartIcon from '../cart-icon.png';
import styles from './InformationBlock.module.scss';

import CheckoutTextField from './Fields/CheckoutTextField';
import CheckoutSelectField from './Fields/CheckoutSelectField';
import SubmitBlock from './SubmitBlock';
import { required, decimal } from '../../../formValidationRules';
import InformationBlock from '../InformationBlock';
import { getCheckoutClient } from '../../../../../services/api-client';

const MissingPriceBlock = ({
    hasPayerEmail, orderId, submitting, invalid, pristine,
}) => {
    const [currencyList, setCurrencyList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation('checkout-frontend');

    const validateRequired = useMemo(() => required(t), []);
    const validateDecimal = useMemo(() => decimal(t), []);

    const getCurrencyList = async () => {
        const client = getCheckoutClient();

        let newCurrencyList = null;

        try {
            newCurrencyList = (await client.getSupportedCurrencies()).getCurrencies();
        } catch (error) {
            captureException(error);
        }

        setIsLoading(false);
        setCurrencyList(newCurrencyList);
    };

    useEffect(() => {
        getCurrencyList();
    }, []);

    return (
        <DebounceLoader loaded={!isLoading}>
            {isLoading
                ? (<></>)
                : (
                    <InformationBlock
                        headerLabel={`${t('order_no')}:`}
                        headerValue={orderId}
                        headerImage={cartIcon}
                    >
                        <div className={styles.missingBlock}>
                            <Field
                                name="priceAmount"
                                type="text"
                                component={CheckoutTextField}
                                label={t('amount_input_label')}
                                validate={[validateRequired, validateDecimal]}
                            />
                            <Field
                                name="currencyDropdown"
                                component={CheckoutSelectField}
                                label={t('currency_input_label')}
                                options={currencyList}
                            />
                            {
                        hasPayerEmail && (
                            <SubmitBlock
                                submitting={submitting}
                                invalid={invalid}
                                pristine={pristine}
                            />
                        )
                    }
                        </div>
                    </InformationBlock>
                )}
        </DebounceLoader>
    );
};

MissingPriceBlock.propTypes = {
    hasPayerEmail: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    orderId: PropTypes.string.isRequired,
};

export default MissingPriceBlock;
