import axios from 'axios';

import TranslationProxyClient from './TranslationProxyClient';

const createTranslationProxyClient = () => {
    const axiosInstance = axios.create({
        baseURL: 'https://translation-proxy.paysera.com',
    });

    return new TranslationProxyClient(axiosInstance);
};

export default createTranslationProxyClient;
