import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { PaymentRequestResponse } from '@paysera/checkout-client';

import DebounceLoader from '../../../DebounceLoader';
import merchantIcon from '../merchant-icon.png';
import styles from './InformationBlock.module.scss';

import BlockRow from './BlockRow';
import InformationBlock from '../InformationBlock';

import { isLoading as isLoadingSelector } from '../../../../../selectors/is-loading';

const SellerBlock = ({ paymentRequest }) => {
    const { t } = useTranslation('checkout-frontend');
    const isLoading = useSelector(state => isLoadingSelector(state, 'payment_request'));

    const renderContent = () => {
        const contactInfo = paymentRequest.getContactInfo();
        if (isEmpty(contactInfo)) {
            return null;
        }

        return (
            <>
                {contactInfo.getContactsUrl() && (
                    <BlockRow
                        label={`${t('website_address')}:`}
                        value={contactInfo.getContactsUrl()}
                    />
                )}
                {contactInfo.getName() && (
                    <BlockRow label={`${t('name')}:`} value={contactInfo.getName()} />
                )}
                {contactInfo.getAddress() && (
                    <BlockRow
                        label={`${t('address')}:`}
                        value={contactInfo.getAddress()}
                    />
                )}
                {contactInfo.getEmail() && (
                    <BlockRow
                        label={`${t('email_address')}:`}
                        value={contactInfo.getEmail()}
                    />
                )}
                {contactInfo.getPhone() && (
                    <BlockRow
                        label={`${t('telephone_number')}:`}
                        value={contactInfo.getPhone()}
                    />
                )}
            </>
        );
    };

    const content = isLoading ? <div /> : renderContent();

    return (
        <InformationBlock
            headerLabel={t('seller')}
            toggleAble
            headerImage={merchantIcon}
        >
            <div className={styles.sellerBlock}>
                <DebounceLoader loaded={!isLoading}>{content}</DebounceLoader>
            </div>
        </InformationBlock>
    );
};

SellerBlock.propTypes = {
    paymentRequest: PropTypes.instanceOf(PaymentRequestResponse).isRequired,
};

export default SellerBlock;
