import { captureException } from '@sentry/browser';
import { useDispatch } from 'react-redux';
import { recreatePayment } from '../actions/payment/recreate-payment';
import { usePaymentStatusRedirect } from './usePaymentStatusRedirect';

export const useRecreatePayment = (
    paymentRequestId,
    paymentRequestCancelUrl,
) => {
    const dispatch = useDispatch();
    const { redirectToHome } = usePaymentStatusRedirect();

    return async () => {
        try {
            await dispatch(recreatePayment(paymentRequestId));
            redirectToHome(paymentRequestId);
        } catch (error) {
            captureException(error);
            window.location.href = paymentRequestCancelUrl;
        }
    };
};
