import { Filter } from '@paysera/http-client-common';

class BusinessFilter extends Filter {
    /**
     * @return {string|null}
     */
    getId() {
        return this.get('id');
    }

    /**
     * @param {string} id
     */
    setId(id) {
        this.set('id', id);
    }

    /**
     * @return {string|null}
     */
    getExcludedId() {
        return this.get('excluded_id');
    }

    /**
     * @param {string} excludedId
     */
    setExcludedId(excludedId) {
        this.set('excluded_id', excludedId);
    }

    /**
     * @return {string|null}
     */
    getUniqueIdentifier() {
        return this.get('unique_identifier');
    }

    /**
     * @param {string} uniqueIdentifier
     */
    setUniqueIdentifier(uniqueIdentifier) {
        this.set('unique_identifier', uniqueIdentifier);
    }

    /**
     * @return {string|null}
     */
    getOwnerId() {
        return this.get('owner_id');
    }

    /**
     * @param {string} ownerId
     */
    setOwnerId(ownerId) {
        this.set('owner_id', ownerId);
    }

    /**
     * @return {Array.<string>|null}
     */
    getPublicUrlParts() {
        return this.get('public_url_parts');
    }

    /**
     * @param {Array.<string>} publicUrlParts
     */
    setPublicUrlParts(publicUrlParts) {
        this.set('public_url_parts', publicUrlParts);
    }

    /**
     * @return {string|null}
     */
    getTitle() {
        return this.get('title');
    }

    /**
     * @param {string} title
     */
    setTitle(title) {
        this.set('title', title);
    }

    /**
     * @return {string|null}
     */
    getStatus() {
        return this.get('status');
    }

    /**
     * @param {string} status
     */
    setStatus(status) {
        this.set('status', status);
    }
}

export default BusinessFilter;
