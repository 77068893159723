import { useRef } from 'react';
import axios from 'axios';

import { getUrlForPath } from '../../../../services/url-provider';
import config from '../../../../config';

export const usePublicClientService = (paymentRequestHash) => {
    const apiUrl = getUrlForPath(config.checkoutUrlPath);

    const path = `/payment-requests/${paymentRequestHash}/public-info`;

    const cancelSource = useRef(axios.CancelToken.source());

    const getPublicInfo = () => {
        cancelSource.current = axios.CancelToken.source();

        return axios.get(`${apiUrl}${path}`, { cancelToken: cancelSource.current.token });
    };

    return {
        getPublicInfo,
        cancelGetPublicInfo: () => cancelSource.current.cancel(),
    };
};
