import React from 'react';
import PropTypes from 'prop-types';

import styles from './Modal.module.scss';

const ModalBody = ({ children }) => (
    <div className={styles.modalBody}>
        {children}
    </div>
);

ModalBody.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ModalBody;
