import { Entity } from '@paysera/http-client-common';

class Checkup extends Entity {
    constructor(data = {}) {
        super(data);
    }

    /**
     * @return {string|null}
     */
    getNextDate() {
        return this.get('next_date');
    }

    /**
     * @param {string} nextDate
     */
    setNextDate(nextDate) {
        this.set('next_date', nextDate);
    }

    /**
     * @return {Number}
     */
    getMonthNumber() {
        return this.get('month_number');
    }

    /**
     * @param {Number} monthNumber
     */
    setMonthNumber(monthNumber) {
        this.set('month_number', monthNumber);
    }
}

export default Checkup;
