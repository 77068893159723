import React from 'react';
import PropTypes from 'prop-types';
import { fieldInputPropTypes } from 'redux-form';

const CheckoutSelectField = (props) => {
    const { input, label, options } = props;

    return (
        <div className="row">
            <div className="col-md-5 text-right">
                <label className="control-label" htmlFor={input.name}>{`${label}:`}</label>
            </div>
            <div className="col-md-7">
                <select className="form-control" id={input.name} {...input}>
                    {options.map(option => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

CheckoutSelectField.propTypes = {
    input: PropTypes.shape({
        name: fieldInputPropTypes.name,
    }).isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.string,
    ).isRequired,
};

export default CheckoutSelectField;
