import { createAction } from 'redux-actions';

const createRoutine = (name) => {
    const types = {
        REQUEST: `${name}_REQUEST`,
        SUCCESS: `${name}_SUCCESS`,
        FAILED: `${name}_FAILED`,
    };
    const actions = {
        request: createAction(types.REQUEST),
        success: createAction(types.SUCCESS),
        failed: createAction(types.FAILED),
    };

    return {
        ...types,
        ...actions,
    };
};

export default createRoutine;
