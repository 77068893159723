import { createRequest, ClientWrapper } from '@paysera/http-client-common';

import CountriesFilter from '../entity/CountriesFilter';
import CountriesResult from '../entity/CountriesResult';

class CountryClient {
    /**
     * @param {ClientWrapper} client
     */
    constructor(client) {
        this.client = client;
    }

    /**
     * Returns list of countries
     * GET /countries
     *
     * @param {CountriesFilter} countriesFilter
     * @return {Promise.<CountriesResult>}
     */
    getCountries(countriesFilter) {
        const request = createRequest(
            'GET',
            `countries`,
            countriesFilter,
        );

        return this.client
            .performRequest(request)
            .then(data => new CountriesResult(data, 'countries'));
    }

}

export default CountryClient;
