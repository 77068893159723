import React from 'react';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';

import CheckoutPage from './components/CheckoutPage';
import ThreeDSecurePage from './components/ThreeDSecurePage';
import NotFoundPage from './components/NotFoundPage';
import SuccessPage from './components/SuccessPage';
import ErrorPage from './components/ErrorPage';

const router = createBrowserRouter([
    {
        path: '/3d-secure/:status',
        element: <ThreeDSecurePage />,
    },
    {
        path: '/payment-request/:paymentRequestHash',
        exact: true,
        element: <CheckoutPage />,
    },
    {
        path: '/payment-request/:paymentRequestHash/success',
        element: <SuccessPage />,
    },
    {
        path: '/payment-request/:paymentRequestHash/error',
        element: <ErrorPage />,
    },
    {
        path: '/ping',
        element: <div>pong</div>,
    },
    {
        path: '/*',
        element: <NotFoundPage />,
    },
]);

export default function Routes() {
    return <RouterProvider router={router} />;
}
