import React, { memo } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';

import styles from './CountrySelect.module.scss';

const CountrySelect = ({
    country,
    countries,
    setCountry,
}) => {
    const { t } = useTranslation('checkout-frontend');
    return (
        <div className={styles.container} data-testid='country-select'>
            <div className={styles.titleWrapper}>
                <span>
                    {t('select_country_title')}
                </span>
            </div>
            <div className={styles.selectWrapper}>
                <Select
                    options={countries}
                    onChange={value => setCountry(value)}
                    value={country}
                    clearable={false}
                />
            </div>
        </div>
    );
};

CountrySelect.propTypes = {
    country: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    }),
    countries: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
    setCountry: PropTypes.func,
};

CountrySelect.defaultProps = {
    country: null,
    countries: [],
    setCountry: noop,
};

export default memo(CountrySelect);
