import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import StartPayment from '../Common/StartPayment/StartPayment';
import VerifyPaymentInformation from '../Common/FullPageWrap/VerifyPaymentInformation';
import MethodsContainer from '../Common/Methods/MethodsContainer';
import Processing from '../Common/CheckoutPage/Processing/Processing';
import { CheckoutView } from '../Common/CheckoutView/CheckoutView';
import { useCheckoutLoad } from '../../hooks/useCheckoutLoad';
import { usePaymentStatusRedirect } from '../../hooks/usePaymentStatusRedirect';

import { isPaid as isPaidSelector } from '../../selectors/payment/is-paid';
import { getCurrentRequest } from '../../selectors/payment/get-current-request';
import { getCurrentStatus } from '../../selectors/payment/get-payment-status';

import { PAYMENT_STATUS } from '../../constants/payment-request';
import { validatePaymentRequestHash } from '../../utils/validatePaymentRequestHash';
import { getHasPaymentAmount } from '../../utils/getHasPaymentAmount';
import { getHasPayerEmail } from '../../utils/getHasPayerEmail';

export const CheckoutPage = () => {
    const paymentRequest = useSelector(state => getCurrentRequest(state));
    const isPaid = useSelector(state => isPaidSelector(state));
    const paymentStatus = useSelector(state => getCurrentStatus(state));

    const hasPaymentMethod = useMemo(() => !!paymentRequest.getMethodKey(), [paymentRequest]);
    const hasPaymentAmount = useMemo(getHasPaymentAmount(paymentRequest), [paymentRequest]);
    const hasPayerEmail = useMemo(getHasPayerEmail(paymentRequest), [paymentRequest]);

    const { redirectToSuccess } = usePaymentStatusRedirect();

    useCheckoutLoad();

    useEffect(() => {
        if (
            (paymentStatus === PAYMENT_STATUS.SUCCESS
                || paymentStatus === PAYMENT_STATUS.INSTANTLY_REFUNDED
                || isPaid)
            && paymentRequest?.getId()
        ) {
            redirectToSuccess(paymentRequest.getId());
        }
    }, [paymentStatus, isPaid, paymentRequest]);

    const content = useMemo(() => {
        if (!paymentRequest && !isEmpty(paymentRequest.getData())) {
            return <div />;
        }

        if (
            paymentStatus === PAYMENT_STATUS.PROCESSING
            && paymentRequest.getData().valid_until
        ) {
            return (
                <Processing
                    paymentRequestHash={paymentRequest.getId()}
                    expirationTime={paymentRequest.getData().valid_until}
                    cancelUrl={paymentRequest.getCancelUrl()}
                />
            );
        }

        if (hasPaymentAmount && hasPayerEmail) {
            if (hasPaymentMethod) {
                return <StartPayment />;
            }

            return <MethodsContainer />;
        }

        const initialValues = {
            currencyDropdown: 'EUR',
        };

        return (
            <VerifyPaymentInformation
                initialValues={initialValues}
                hasPaymentAmount={hasPaymentAmount}
                hasPayerEmail={hasPayerEmail}
                paymentRequest={paymentRequest}
            />
        );
    }, [
        paymentRequest,
        isPaid,
        hasPaymentAmount,
        hasPayerEmail,
        hasPaymentMethod,
    ]);

    return <CheckoutView>{content}</CheckoutView>;
};

const ConnectedCheckoutPage = React.memo(CheckoutPage);

export default function ValidatedCheckoutPage() {
    const params = useParams();
    if (!validatePaymentRequestHash(params.paymentRequestHash)) {
        return <Navigate to="/not-found" replace />;
    }
    return <ConnectedCheckoutPage />;
}
