import { Entity } from '@paysera/http-client-common';

class Country extends Entity {
    constructor(data = {}) {
        super(data);
    }

    /**
     * @return {string}
     */
    getName() {
        return this.get('name');
    }

    /**
     * @param {string} name
     */
    setName(name) {
        this.set('name', name);
    }

    /**
     * @return {string}
     */
    getAlpha2Code() {
        return this.get('alpha2_code');
    }

    /**
     * @param {string} alpha2Code
     */
    setAlpha2Code(alpha2Code) {
        this.set('alpha2_code', alpha2Code);
    }

    /**
     * @return {string}
     */
    getCallingCode() {
        return this.get('calling_code');
    }

    /**
     * @param {string} callingCode
     */
    setCallingCode(callingCode) {
        this.set('calling_code', callingCode);
    }
}

export default Country;
