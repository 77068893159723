import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { getCurrentRequest } from '../../../selectors/payment/get-current-request';
import PaymentInformation from './PaymentInformation';
import CancelUrlBlock from './CancelUrlBlock';

import styles from './FullPageContentWithPaymentInformation.module.scss';

const FullPageContent = ({
    children, hasPaymentAmount,
    hasPayerEmail,
}) => {
    const paymentRequest = useSelector(state => getCurrentRequest(state));
    const { t } = useTranslation('checkout-frontend');
    const cancelUrlData = useMemo(() => {
        if (!paymentRequest?.getContactInfo()?.getContactsUrl() || !paymentRequest.getCancelUrl()) {
            return null;
        }

        return {
            url: paymentRequest.getCancelUrl(),
            label: `${t('cancel_payment_and_go_back')} ${t('to')} ${paymentRequest.getContactInfo().getContactsUrl()}`,
        };
    }, [paymentRequest, t]);

    return (
        <section className={styles['payment-section']} key="fullPageWithContent">
            <div className="container">
                <div className="row justify-content-center">
                    {(hasPaymentAmount && hasPayerEmail) && (
                    <div className={`col-12 col-md-6 col-lg-5 ${styles.leftPanel}`}>
                        <div className={styles.innerLeftPanel}>
                            <PaymentInformation paymentRequest={paymentRequest} />
                        </div>
                    </div>
                ) }

                    <div className={`col-12 col-md-6 col-lg-7 ${styles.rightPanel}`}>
                        <div className={styles.innerLeftPanel}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>

            {cancelUrlData && <CancelUrlBlock url={cancelUrlData.url} label={cancelUrlData.label} />}
        </section>
    );
};

FullPageContent.propTypes = {
    children: PropTypes.node.isRequired,
    hasPaymentAmount: PropTypes.bool.isRequired,
    hasPayerEmail: PropTypes.bool.isRequired,
};

export default FullPageContent;
