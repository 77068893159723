import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import HeaderLabel from './HeaderLabel';
import HeaderValue from './HeaderValue';
import InlinePrice from '../InlinePrice';
import BusinessLogo from '../FullPageWrap/PaymentInformation/BusinessLogo';

import { getBusinessLogo } from '../../../selectors/business/get-business-logo';
import { getCurrentRequest } from '../../../selectors/payment/get-current-request';

import blackCloseModalIcon from '../../../images/black-close-icon.svg';

import styles from './Modal.module.scss';

const ModalHeader = ({
    childWindow,
}) => {
    const businessLogo = useSelector(state => getBusinessLogo(state));
    const paymentRequest = useSelector(state => getCurrentRequest(state));
    const { t } = useTranslation('checkout-frontend');
    const closeModal = () => {
        childWindow.sendMessageToParent({
            method: 'error',
            params: {
                code: 'user-closed',
            },
        });

        childWindow.sendMessageToParent({
            method: 'close',
            params: {},
        });
    };

    const renderHeader = () => {
        const contactInfo = paymentRequest.getContactInfo();
        const price = paymentRequest.getPrice();

        return (
            <div className={styles.modalHeader}>
                <div className="container">
                    <div className="row">
                        <div className="merchant-logo col-3">
                            <BusinessLogo businessLogo={businessLogo} className={styles.logo} />
                        </div>

                        <div className="col-8">
                            {contactInfo.getName() && (
                                <div className="d-flex align-items-center">
                                    <HeaderLabel>{`${t('seller')}:`}</HeaderLabel>
                                    <HeaderValue>{contactInfo.getName()}</HeaderValue>
                                </div>
                            )}

                            <div className="d-flex align-items-center">
                                <HeaderLabel>{`${t('order_no')}:`}</HeaderLabel>
                                <HeaderValue>{paymentRequest.getOrderId()}</HeaderValue>
                            </div>

                            <div className="d-flex align-items-center">
                                <HeaderLabel>{`${t('amount')}:`}</HeaderLabel>
                                <HeaderValue isPrice>
                                    {!isEmpty(price) && (
                                        <InlinePrice amount={price.getAmount()} currency={price.getCurrency()} />
                                    )}
                                </HeaderValue>
                            </div>

                        </div>
                    </div>

                    <button type="button" className="close-btn" onClick={closeModal}>
                        <img src={blackCloseModalIcon} alt="" className="cookies-close-icon" />
                    </button>
                </div>
            </div>
        );
    };

    return renderHeader();
};

ModalHeader.propTypes = {
    childWindow: PropTypes.object.isRequired,
};

export default ModalHeader;
