import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import Success from '../Common/CheckoutPage/Success/Success';
import { CheckoutView } from '../Common/CheckoutView/CheckoutView';
import WizardHeader from '../Common/WizardHeader';
import { useCheckoutLoad } from '../../hooks/useCheckoutLoad';
import { getCurrentRequest } from '../../selectors/payment/get-current-request';

function SuccessPage() {
    useCheckoutLoad();

    const isModal = useMemo(() => window.self !== window.top, [window]);
    const paymentRequest = useSelector(state => getCurrentRequest(state));

    return (
        <CheckoutView>
            <WizardHeader />
            {paymentRequest.getAcceptUrl()
            && <Success successUrl={paymentRequest.getAcceptUrl()} isModal={isModal} />}
        </CheckoutView>
    );
}

export default SuccessPage;
