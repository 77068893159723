export default {
    supportedLocales: [
        { name: 'English', code: 'en' },
        { name: 'Russian', code: 'ru' },
        { name: 'Lithuanian', code: 'lt' },
    ],
    defaultLocale: 'en',
    checkoutUrlPath: '/checkout/rest/v1',
    paymentGatewayCardUrlPath: '/payment-gateway/card/',
    businessUrl: 'https://business.paysera.com/business/rest/v1',
    countriesUrl: 'https://bank.paysera.com/country/rest/v1',
    cardGatewayUrl: 'https://card-gateway-api.paysera.com',
    checkoutUrl: 'https://checkout-api.paysera.com',
    sentryDsn: 'https://f633fddd159244e7a8125193b6ccc43a@sentry.paysera.com/66',
};
