import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import loadTranslations from './loadTranslations';

export default (locale) => {
    i18next
        .use({
            type: 'backend',
            init: () => null,
            read: loadTranslations,
        })
        .use(initReactI18next)
        .init({
            lng: locale,
            debug: false,
            fallbackLng: 'en',
            ns: ['checkout-frontend'],
            keySeparator: '',
            interpolation: {
                prefix: '%',
                suffix: '%',
                escapeValue: false,
            },
        });

    i18next.language = locale;

    return i18next;
};
