import axios from 'axios';
import { createCheckoutClient } from '@paysera/checkout-client';
import { createBusinessClient } from '@paysera/business-client';
import { createCountryClient } from '@paysera/country-client';

import { getUrlForPath } from './url-provider';
import config from '../config';

export const getClientForPaymentRequestHashAndUrlPath = (urlPath) => {
    const url = getUrlForPath(urlPath);

    return axios.create({
        baseURL: url,
    });
};

export const getCheckoutClient = () => {
    const apiUrl = getUrlForPath(config.checkoutUrlPath);
    return createCheckoutClient({
        baseURL: apiUrl,
    });
};

export const getCountriesClient = params => createCountryClient({
    baseURL: config.countriesUrl,
    ...params,
});

export const getBusinessClient = () => createBusinessClient({
    baseURL: config.businessUrl,
});
