import { handleActions } from 'redux-actions';

import { FETCH_PAYMENT_METHODS_SUCCESS } from '../../actions/payment/methods';

export default handleActions({
    [FETCH_PAYMENT_METHODS_SUCCESS]: (state, { payload }) => ({
        ...state,
        ...payload,
    }),
}, {});
