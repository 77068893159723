import React, {
    useState,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { captureException } from '@sentry/browser';

import { Icon } from '../../../ui/Icon/Icon';
import { ICON_COLLECTION } from '../../../ui/Icon/icon-list';
import { usePublicClientService } from '../hooks/usePublickClientService';
import { PAYMENT_STATUS } from '../../../../constants/payment-request';
import { getTimeDifferenceWithCurrentTime } from '../../../../utils/time-manager';
import { parseTimestampToString } from '../utils/timestemp-formatter';
import { recreatePayment } from '../../../../actions/payment/recreate-payment';
import { fetchPaymentRequest } from '../../../../actions/payment/fetch-payment-request';

import './Processing.global.scss';

const LONG_POLLING_INTERVAL = 10000;

const Processing = (props) => {
    const {
        paymentRequestHash,
        expirationTime,
        fetchPublicInfoDispatch,
        cancelUrl,
    } = props;

    const { t } = useTranslation('checkout-frontend');

    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentRequestValid, setIsPaymentRequestValid] = useState(
        getTimeDifferenceWithCurrentTime(expirationTime) > 0,
    );
    const [remainingTimestamp, setRemainingTimestamp] = useState(
        getTimeDifferenceWithCurrentTime(expirationTime),
    );
    const [timerInterval, setTimerInterval] = useState(null);

    const isLongPollingAllowed = useRef(
        getTimeDifferenceWithCurrentTime(expirationTime) > 0,
    );
    const { getPublicInfo, cancelGetPublicInfo } = usePublicClientService(paymentRequestHash);

    const dispatch = useDispatch();

    const formattedRemainingTime = useMemo(() => parseTimestampToString(remainingTimestamp), [remainingTimestamp]);

    const performLongPollingPaymentStatus = async () => {
        if (!isLongPollingAllowed.current) return;

        try {
            const { data: { status: paymentStatus } } = await getPublicInfo();

            if (paymentStatus !== PAYMENT_STATUS.PROCESSING) {
                await fetchPublicInfoDispatch(paymentRequestHash);

                return;
            }

            setTimeout(() => performLongPollingPaymentStatus(), LONG_POLLING_INTERVAL);
        } catch (error) {
            captureException(error);
        }
    };

    const handleTimerCompletion = useCallback(async () => {
        setIsLoading(true);
        cancelGetPublicInfo();
        isLongPollingAllowed.current = false;

        try {
            await new Promise((resolve) => {
                setTimeout(async () => {
                    const { data: { status: paymentStatus } } = await getPublicInfo();

                    if (paymentStatus !== PAYMENT_STATUS.PROCESSING) {
                        await fetchPublicInfoDispatch(paymentRequestHash);

                        resolve(paymentStatus);
                        return;
                    }

                    setIsPaymentRequestValid(false);

                    resolve(paymentStatus);
                }, LONG_POLLING_INTERVAL);
            });
        } catch (error) {
            captureException(error);
        } finally {
            setIsLoading(false);
        }
    }, [cancelGetPublicInfo, getPublicInfo]);

    const setUpTimer = () => {
        const localTimerInterval = setInterval(async () => {
            const timerRemainingTime = getTimeDifferenceWithCurrentTime(expirationTime);
            setRemainingTimestamp(timerRemainingTime);

            if (timerRemainingTime <= 0) {
                clearInterval(localTimerInterval);
                await handleTimerCompletion();
            }
        }, 1000);

        return localTimerInterval;
    };

    useEffect(() => {
        if (!isPaymentRequestValid) {
            return undefined;
        }

        performLongPollingPaymentStatus();
        setTimerInterval(setUpTimer());

        return () => clearInterval(timerInterval);
    }, [paymentRequestHash]);

    const recreatePaymentHandler = async () => {
        setIsLoading(true);

        try {
            clearInterval(timerInterval);
            cancelGetPublicInfo();
            isLongPollingAllowed.current = false;

            await dispatch(recreatePayment(paymentRequestHash));

            const { data: { status: paymentStatus } } = await getPublicInfo();

            if (paymentStatus !== PAYMENT_STATUS.PROCESSING) {
                await fetchPublicInfoDispatch(paymentRequestHash);
            }
        } catch (error) {
            captureException(error);

            window.location.href = cancelUrl;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='processing'>
            {isPaymentRequestValid && (
                <div className="payment-processing">
                    <Icon
                        iconComponent={ICON_COLLECTION.info}
                        className="payment-processing-icon payment-processing-icon--info"
                    />

                    <div
                        className="payment-processing__text"
                        dangerouslySetInnerHTML={{ __html: t('payment_processing_timer_text') }}
                    />
                    <h2 className="payment-processing__timer">
                        {formattedRemainingTime}
                    </h2>

                    <p className="recreate-text">
                        {t('payment_processing_recreate_btn_text')}
                    </p>

                    <button
                        type="button"
                        className="recreate-button"
                        onClick={recreatePaymentHandler}
                    >
                        {t('payment_processing_recreate_btn')}
                    </button>
                </div>
            )}

            {!isPaymentRequestValid && (
                <div className="payment-error">
                    <Icon
                        iconComponent={ICON_COLLECTION.cancel}
                        className="payment-processing-icon payment-processing-icon--error"
                    />

                    <p className="payment-error__title">
                        {t('payment_processing_error_text')}
                    </p>

                </div>
            )}

            {isLoading && (
                <div className="loader-wrap">
                    <div className="lds-roller">
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            )}
        </div>
    );
};

Processing.propTypes = {
    fetchPublicInfoDispatch: PropTypes.func.isRequired,
    expirationTime: PropTypes.number.isRequired,
    paymentRequestHash: PropTypes.string.isRequired,
    cancelUrl: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchPublicInfoDispatch: fetchPaymentRequest,
}, dispatch);

export default connect(null, mapDispatchToProps)(Processing);
