import { captureException } from '@sentry/browser';
import { createAction } from 'redux-actions';

import { getCheckoutClient } from '../../services/api-client';

export const FETCH_PAYMENT_REQUEST_REQUEST = 'payment/FETCH_PAYMENT_REQUEST_REQUEST';
export const FETCH_PAYMENT_REQUEST_SUCCESS = 'payment/FETCH_PAYMENT_REQUEST_SUCCESS';
export const FETCH_PAYMENT_REQUEST_FAILED = 'payment/FETCH_PAYMENT_REQUEST_FAILED';

const fetchPaymentRequestRequest = createAction(FETCH_PAYMENT_REQUEST_REQUEST);
const fetchPaymentRequestSuccess = createAction(FETCH_PAYMENT_REQUEST_SUCCESS);
const fetchPaymentRequestFailed = createAction(FETCH_PAYMENT_REQUEST_FAILED);

export const fetchPaymentRequest = paymentRequestHash => async (dispatch) => {
    dispatch(fetchPaymentRequestRequest());

    const client = getCheckoutClient();

    try {
        const { data } = await client.getPaymentRequestPublicInfo(paymentRequestHash);
        await dispatch(fetchPaymentRequestSuccess(data));
    } catch (error) {
        captureException(error);
        await dispatch(fetchPaymentRequestFailed());
        throw error;
    }
};
