import React from 'react';
import PropTypes from 'prop-types';

import FullPageFooter from './FullPageFooter';
import FullPageHeader from './FullPageHeader';

import styles from './FullPageWrap.module.scss';

const FullPageWrap = ({ children }) => (
    <>
        <div className={styles.fullPage} key="fullPageWithContent">
            <FullPageHeader />
            {children}
            <div className={styles.footerPush} />
        </div>
        <FullPageFooter key="fullPageFooter" />
    </>
);

FullPageWrap.propTypes = {
    children: PropTypes.node.isRequired,
};

export default FullPageWrap;
