import { captureException } from '@sentry/browser';
import { createAction } from 'redux-actions';

import { getCheckoutClient } from '../../services/api-client';
import { getCurrentRequest } from '../../selectors/payment/get-current-request';
import {
    CHECKOUT_LOAD_ERROR,
    CHECKOUT_LOAD_START,
    CHECKOUT_LOAD_SUCCESS,
} from '../checkout/load-checkout';

const checkoutLoadStart = createAction(CHECKOUT_LOAD_START);
const checkoutLoadSuccess = createAction(CHECKOUT_LOAD_SUCCESS);
const checkoutLoadError = createAction(CHECKOUT_LOAD_ERROR);

export const recreatePayment = (paymentRequestHash = null) => async (dispatch, getState) => {
    dispatch(checkoutLoadStart());

    const paymentRequest = getCurrentRequest(getState());

    try {
        const checkoutClient = getCheckoutClient();

        await checkoutClient.recreatePaymentRequest(
            paymentRequestHash || paymentRequest.getId(),
        );

        await dispatch(checkoutLoadSuccess());
    } catch (error) {
        captureException(error);
        await dispatch(checkoutLoadError());
        window.location.href = paymentRequest.getCancelUrl();
    }
};
