import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';

const concat = (objValue, srcValue) => ((isArray(objValue)) ? objValue.concat(srcValue) : undefined);

const parseToGroups = (methods, getGroupKey) => methods.reduce((accum, method) => {
    const groupKey = getGroupKey(method);

    return groupKey ? mergeWith(accum, { [groupKey]: [method] }, concat) : accum;
}, {});

export default parseToGroups;
