import React from 'react';
import PropTypes from 'prop-types';
import FullPageFooter from './FullPageFooter';
import FullPageHeader from './FullPageHeader';
import FullPageContent from './FullPageContent';

const FullPageWrapWithPaymentInformation = ({
    children,
    hasPaymentAmount,
    hasPayerEmail,
}) => (
    <div className='full-page-wrap'>
        <FullPageHeader />
        <FullPageContent
            hasPaymentAmount={hasPaymentAmount}
            hasPayerEmail={hasPayerEmail}
        >
            {children}
        </FullPageContent>
        <FullPageFooter key="fullPageFooter" />
    </div>
);

FullPageWrapWithPaymentInformation.propTypes = {
    children: PropTypes.node.isRequired,
    hasPaymentAmount: PropTypes.bool.isRequired,
    hasPayerEmail: PropTypes.bool.isRequired,
};

export default FullPageWrapWithPaymentInformation;
