export const middlewareAddHeader = headers => ({
    onRequest: props => ({
        ...props,
        headers: {
            ...props.headers,
            ...headers,
        },
    }),
});

export const middlewareAddLanguage = currentLocale => middlewareAddHeader({
    'Accept-Language': currentLocale,
});
