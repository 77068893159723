import React from 'react';
import PropTypes from 'prop-types';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';

const CheckoutTextField = ({
    input, label, type, meta: { touched, error },
}) => (
    <div className="row">
        <div className="col-md-5 text-right">
            <label className="control-label" htmlFor={input.name}>{`${label}:`}</label>
        </div>
        <div className="col-md-7">
            <input
                className="form-control"
                {...input}
                id={input.name}
                placeholder={label}
                type={type}
            />
            {touched && (error && <span className="text-danger">{error}</span>) }
        </div>
    </div>
);

CheckoutTextField.propTypes = {
    input: PropTypes.shape({
        name: fieldInputPropTypes.name,
    }).isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    meta: PropTypes.shape({
        touched: fieldMetaPropTypes.touched,
        error: fieldMetaPropTypes.error,
    }).isRequired,
};

export default CheckoutTextField;
