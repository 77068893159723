import { combineReducers } from 'redux';

import currentRequest from './current-request';
import startedPayment from './started-payment';
import isPaid from './is-paid';
import methods from './methods';

export default combineReducers({
    currentRequest,
    startedPayment,
    isPaid,
    methods,
});
