import { Filter } from '@paysera/http-client-common';

class ContactFilter extends Filter {
    /**
     * @return {string|null}
     */
    getCountry() {
        return this.get('country');
    }

    /**
     * @param {string} country
     */
    setCountry(country) {
        this.set('country', country);
    }

    /**
     * @return {string|null}
     */
    getLanguage() {
        return this.get('language');
    }

    /**
     * @param {string} language
     */
    setLanguage(language) {
        this.set('language', language);
    }

    /**
     * @return {string}
     */
    getBusinessId() {
        return this.get('business_id');
    }

    /**
     * @param {string} businessId
     */
    setBusinessId(businessId) {
        this.set('business_id', businessId);
    }
}

export default ContactFilter;
