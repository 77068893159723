// eslint-disable-next-line import/no-extraneous-dependencies
import iframeResize from 'iframe-resizer/js/iframeResizer';

export const createIframe = ({
    paymentRequestHash,
    iframeWrapper,
    parentWindow,
    iframeUrl,
}) => {
    const iframeEl = document.createElement('iframe');
    iframeEl.setAttribute('src', iframeUrl);
    iframeEl.setAttribute('name', `gateway-card-form-${paymentRequestHash}`);
    iframeEl.setAttribute('class', 'card-gateway-wizard');
    iframeEl.setAttribute('id', `payment-iframe-${paymentRequestHash}`);
    iframeEl.setAttribute('data-testid', `payment-iframe-${paymentRequestHash}`);

    iframeEl.style.width = '100%';

    if (iframeEl) {
        iframeResize({ checkOrigin: false }, iframeEl);
    }

    if (iframeWrapper.current) {
        // eslint-disable-next-line no-param-reassign
        iframeWrapper.current.innerHTML = '';
        iframeWrapper.current.appendChild(iframeEl);
        parentWindow.addChild(iframeEl.name, iframeEl.contentWindow);
    }
};
