import { Filter } from '@paysera/http-client-common';

class CountriesFilter extends Filter {
    /**
     * @return {Array.<string>|null}
     */
    getTypes() {
        return this.get('types');
    }

    /**
     * @param {Array.<string>} types
     */
    setTypes(types) {
        this.set('types', types);
    }

    /**
     * @return {string|null}
     */
    getCountry() {
        return this.get('country');
    }

    /**
     * @param {string} country
     */
    setCountry(country) {
        this.set('country', country);
    }
}

export default CountriesFilter;
