export const getTimeDifferenceWithCurrentTime = (expirationTime) => {
    if (!expirationTime) return 0;

    const expirationTimestamp = Math.floor(expirationTime);
    const currentDateTimestamp = Math.floor(Date.now() / 1000);

    if (expirationTimestamp < currentDateTimestamp) {
        return 0;
    }

    return expirationTimestamp - currentDateTimestamp;
};
