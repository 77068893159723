import { ReactComponent as CancelIcon } from './collection/cancel-circle-icon.svg';
import { ReactComponent as InfoIcon } from './collection/info-circle-icon.svg';
import { ReactComponent as OklIcon } from './collection/ok-circle-icon.svg';
import { ReactComponent as CloseIcon } from './collection/close-icon.svg';

export const ICON_COLLECTION = {
    cancel: CancelIcon,
    info: InfoIcon,
    success: OklIcon,
    close: CloseIcon,
};
