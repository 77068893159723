import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import { reducer as formReducer } from 'redux-form';

import business from './business';
import payment from './payment';
import isLoading from './is-loading';
import countries from './countries';

export default () => combineReducers({
    business,
    payment,
    localize: localizeReducer,
    isLoading,
    countries,
    form: formReducer,
});
