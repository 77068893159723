import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';

import { SimpleLoader } from './Common/SimpleLoader';
import LocaleProviderWrapper from './LocaleProviderWrapper';
import App from './App';

const Root = ({ store }) => (
    <Provider store={store}>
        <LocalizeProvider store={store}>
            <LocaleProviderWrapper>
                <Suspense fallback={<SimpleLoader />}>
                    <App />
                </Suspense>
            </LocaleProviderWrapper>
        </LocalizeProvider>
    </Provider>
);

Root.propTypes = {
    store: PropTypes.object.isRequired,
};

export default Root;
