import React from 'react';
import PropTypes from 'prop-types';

export const Icon = ({ className, iconComponent }) => {
    const Component = iconComponent;

    return (
        <figure className={className}>
            <Component />
        </figure>
    );
};

Icon.propTypes = {
    className: PropTypes.string,
    iconComponent: PropTypes.elementType.isRequired,
};

Icon.defaultProps = {
    className: '',
};
