import { Country } from '@paysera/country-client';

const createCountry = ({ name, alpha2Code }) => {
    const country = new Country();

    country.setName(name);
    country.setAlpha2Code(alpha2Code);

    return country;
};

export default createCountry;
