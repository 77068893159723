import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import config from '../../config';
import emails from './Config/emails';

export const getLocalesList = () => config.supportedLocales.map(locale => locale.code);

const supportedLocales = getLocalesList();

export const getEmailLink = (type, locale) => {
    let activeLocale = supportedLocales.find(supportedLocale => supportedLocale === locale) || config.defaultLocale;

    if (!(activeLocale && activeLocale.length > 0)) {
        Sentry.captureException('Default locale is missing in config file');
        activeLocale = 'en';
    }

    if (get(emails, [type, activeLocale], null)) {
        return emails[type][activeLocale];
    }

    Sentry.addBreadcrumb({
        data: {
            type,
            locale,
            activeLocale,
            emails,
        },
        category: 'utilities, getEmailLink function',
        level: 'error',
    });
    Sentry.captureException('getEmailLink failed to get e-mail');

    return null;
};
