import React from 'react';
import PropTypes from 'prop-types';

import styles from './BlockRow.module.scss';

const BlockRow = ({ label, value }) => (
    <div className={`row ${styles.blockRow}`}>
        <div className="col-xs-6 col-md-5 text-right">
            {/* eslint-disable-next-line */}
            <label className={styles.blockRowLabel}>{label}</label>
        </div>
        <div className="col">
            <p className={styles.blockRowValue}>{value}</p>
        </div>
    </div>
);

BlockRow.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
};

BlockRow.defaultProps = {
    value: '',
};

export default BlockRow;
