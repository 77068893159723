import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Navigate } from 'react-router-dom';

import ModalWrap from '../Common/ModalWrap/ModalWrap';
import FullPageWrap from '../Common/FullPageWrap/FullPageWrap';
import Status from './Status';

const ThreeDSecurePage = ({ match }) => {
    const renderContent = () => {
        const Wrap = window.self !== window.top ? ModalWrap : FullPageWrap;

        if (match.params.status === 'success') {
            return <Wrap><Status status="success" /></Wrap>;
        }

        return <Wrap><Status status="error" /></Wrap>;
    };

    return renderContent();
};

ThreeDSecurePage.propTypes = {
    match: PropTypes.object.isRequired,
};

const ValidatedThreeDSecurePage = () => {
    const params = useParams();
    if (params.status !== 'success' && params.status !== 'failure') {
        return <Navigate to="/not-found" replace />;
    }
    return <ThreeDSecurePage match={params} />;
};

export default ValidatedThreeDSecurePage;
