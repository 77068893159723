import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styles from './LanguageSelector.module.scss';

import { getCurrentLocale } from '../../../selectors/get-current-locale';
import { changeLocale as changeLocaleAction } from '../../../actions/change-locale';
import config from '../../../config';

export const LanguageSelectorComponent = ({
    className,
}) => {
    const dispatch = useDispatch();
    const changeLocale = locale => dispatch(changeLocaleAction(locale));
    const locale = useSelector(state => getCurrentLocale(state));
    const { t } = useTranslation('checkout-frontend');
    const { supportedLocales, defaultLocale } = config;

    const handleChange = (option) => {
        changeLocale(option.value);
    };

    const renderOption = option => (
        <div className={styles.optionWrap}>
            <img src={require(`../../../images/flags/${option.value}.gif`)} alt="" />
            {option.label}
        </div>
    );

    const options = supportedLocales.map(language => ({
        label: t(`language_${language.code}`),
        value: language.code,
    }));

    const getDefaultOption = () => {
        const defaultOption = options.find(option => option.value === defaultLocale);

        return defaultOption || null;
    };

    const getCurrentOption = () => {
        const currentOption = options.find(option => option.value === locale);

        return currentOption || null;
    };

    return (
        <div className={className} data-testid="language-selector">
            <Select
                autoBlur
                searchable={false}
                clearable={false}
                options={options}
                onChange={handleChange}
                defaultValue={getDefaultOption()}
                value={getCurrentOption()}
                formatOptionLabel={renderOption}
            />
        </div>
    );
};

LanguageSelectorComponent.propTypes = {
    className: PropTypes.string.isRequired,
};

export default LanguageSelectorComponent;
