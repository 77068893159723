import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Money } from '@paysera/money';
import { Email, PaymentRequestResult } from '@paysera/checkout-client';
import {
    resolveErrorMessage,
    ErrorDataMessageConfig,
    ErrorMessageConfig,
    HttpErrorMessageConfig,
    ErrorStatusMessageConfig,
} from '@paysera/error-message-resolver';

import PriceBlock from './PaymentInformation/InformationBlock/PriceBlock';
import MissingPriceBlock from './PaymentInformation/InformationBlock/MissingPriceBlock';
import MissingPayerBlock from './PaymentInformation/InformationBlock/MissingPayerBlock';
import BusinessLogo from './PaymentInformation/BusinessLogo';
import SellerBlock from './PaymentInformation/InformationBlock/SellerBlock';
import { getBusinessLogo } from '../../../selectors/business/get-business-logo';
import { loadCheckout } from '../../../actions/checkout/load-checkout';
import { getCheckoutClient } from '../../../services/api-client';

const VerifyPaymentInformation = ({
    hasPaymentAmount,
    hasPayerEmail,
    handleSubmit,
    submitting,
    invalid,
    pristine,
    paymentRequest,
}) => {
    const dispatch = useDispatch();

    const businessLogo = useSelector(state => getBusinessLogo(state));
    const { t } = useTranslation('checkout-frontend');
    const submitMissingInfo = async (values) => {
        const errorContainer = {};

        const client = getCheckoutClient();

        if (!hasPayerEmail) {
            const { payerEmail } = values;

            const email = new Email();
            email.setEmail(payerEmail);

            try {
                await client.setPaymentRequestMissingEmail(paymentRequest.getId(), email);
            } catch (error) {
                errorContainer.payerEmail = await resolveErrorMessage(
                    error,
                    new ErrorMessageConfig(
                        new HttpErrorMessageConfig()
                            .setDefault(t('payment_request_unknown_error'))
                            .setNetwork(t('network_error'))
                            .setErrorData(
                                new ErrorDataMessageConfig().setConfig({
                                    invalid_state: Promise.resolve(t('payer_email_invalid_state')),
                                    invalid_parameters: Promise.resolve(t('payer_email_invalid_parameters')),
                                }),
                            )
                            .setStatus(
                                new ErrorStatusMessageConfig().setConfig({
                                    404: t('payment_request_not_found'),
                                    500: t('payment_request_server_error'),
                                    503: t('payment_request_server_error'),
                                }),
                            ),
                    ),
                );
            }
        }

        if (hasPayerEmail && !hasPaymentAmount) {
            const { priceAmount, currencyDropdown: currency } = values;

            const amount = new Money(priceAmount, currency || 'EUR');

            try {
                await client.setPaymentRequestMissingPrice(paymentRequest.getId(), amount);
            } catch (error) {
                errorContainer.priceAmount = await resolveErrorMessage(
                    error,
                    new ErrorMessageConfig(
                        new HttpErrorMessageConfig()
                            .setDefault(t('payment_request_unknown_error'))
                            .setNetwork(t('network_error'))
                            .setErrorData(
                                new ErrorDataMessageConfig().setConfig({
                                    invalid_state: Promise.resolve(t('payment_amount_invalid_state')),
                                    invalid_parameters: Promise.resolve(t('payment_amount_invalid_parameters')),
                                    not_acceptable: Promise.resolve(t('payment_amount_invalid_parameters')),
                                }),
                            )
                            .setStatus(
                                new ErrorStatusMessageConfig().setConfig({
                                    404: t('payment_request_not_found'),
                                    500: t('payment_request_server_error'),
                                    503: t('payment_request_server_error'),
                                }),
                            ),
                    ),
                );
            }
        }

        if (Object.keys(errorContainer).length > 0) {
            throw new SubmissionError(errorContainer);
        }

        await dispatch(loadCheckout(paymentRequest.getId()));

        return null;
    };

    return (
        <>
            <BusinessLogo businessLogo={businessLogo} />
            <form
                name="verifyPaymentInformationForm"
                className="clearfix"
                onSubmit={handleSubmit(submitMissingInfo)}
                data-testid="verifyPaymentInformationForm"
            >
                {hasPayerEmail && (hasPaymentAmount ? (
                    <PriceBlock
                        amount={paymentRequest.getPrice().getAmount()}
                        currency={paymentRequest.getPrice().getCurrency()}
                        orderId={paymentRequest.getOrderId()}
                        description={paymentRequest.getDescription()}
                    />
                    ) : (
                        <MissingPriceBlock
                            orderId={paymentRequest.getOrderId()}
                            hasPayerEmail={hasPayerEmail}
                            submitting={submitting}
                            invalid={invalid}
                            pristine={pristine}
                        />
                    ))}

                {!hasPayerEmail && (
                    <MissingPayerBlock
                        data-testid="missingPayerBlock"
                        payer={paymentRequest.getPayer()}
                        submitting={submitting}
                        invalid={invalid}
                        pristine={pristine}
                    />
                )}
            </form>
            <SellerBlock paymentRequest={paymentRequest} />
        </>
    );
};

VerifyPaymentInformation.propTypes = {
    hasPaymentAmount: PropTypes.bool.isRequired,
    hasPayerEmail: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    paymentRequest: PropTypes.instanceOf(PaymentRequestResult).isRequired,
};

const VerifyPaymentInformationForm = reduxForm({
    form: 'paymentInformationForm',
})(VerifyPaymentInformation);

export default VerifyPaymentInformationForm;
