import storage from 'local-storage-fallback';
import { getActiveLanguage } from 'react-localize-redux';
import isUndefined from 'lodash/isUndefined';

import { normalizeLocale } from '../i18n/translations/normalizeLocale';

import config from '../config';

export const getCurrentLocale = (state) => {
    const localeInStorage = storage.getItem('locale');

    if (!isUndefined(state.localize)) {
        const activeLocale = getActiveLanguage(state.localize);

        if (!isUndefined(activeLocale)) {
            return activeLocale.code;
        }
    }

    if (localeInStorage !== null) {
        return normalizeLocale(localeInStorage);
    }

    return config.defaultLocale;
};
