import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

const SubmitBlock = ({
    submitting, invalid, pristine,
}) => {
    const { t } = useTranslation('checkout-frontend');
    return (
        <div className="form-group-submit text-right">
            <button
                className="btn btn-primary pull-right"
                type="submit"
                disabled={submitting || invalid || pristine}
            >
                {t('continue_button')}
            </button>
        </div>
    );
};

SubmitBlock.propTypes = {
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
};

export default SubmitBlock;
