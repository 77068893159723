import React from 'react';

import Routes from '../routes';
import AppHead from './AppHead';

const App = () => (
    <>
        <AppHead />
        <Routes />
    </>
);

export default App;
