import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import Loader from './Loader';

const DebounceLoader = ({ loaded, bounceTimeout, children }) => {
    const [isLoaded, setLoadingStatus] = useState(true);
    const debounceRef = useRef(debounce(() => setLoadingStatus(false), bounceTimeout));

    const initiate = () => {
        if (loaded) {
            debounceRef.current.cancel();
            setLoadingStatus(true);
        } else {
            debounceRef.current();
        }
    };

    useEffect(() => {
        initiate();
    }, [loaded]);

    return (
        <Loader loaded={isLoaded}>
            {children}
        </Loader>
    );
};

DebounceLoader.propTypes = {
    loaded: PropTypes.bool.isRequired,
    bounceTimeout: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

DebounceLoader.defaultProps = {
    bounceTimeout: 1000,
};

export default DebounceLoader;
