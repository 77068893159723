import { Event } from 'paysera-across-windows';
import { captureException } from '@sentry/browser';

import {
    PAYMENT_STATUS,
    DELAY_HANDLE_WAITING_STATUS,
    COUNT_RETRY_HANDLE_WAITING_STATUS,
} from '../../../../constants/payment-request';

export const iframeEventListener = async ({
    parentWindow,
    paymentRequest,
    paymentStatusRedirect,
    getPublicInfo,
    recreatePaymentHandler,
}) => {
    const handlePaymentRequestStatus = async (retryCount = 0) => {
        if (retryCount >= COUNT_RETRY_HANDLE_WAITING_STATUS) {
            throw new Error('Max retry limit of payment status');
        }

        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                try {
                    const { data: { status: paymentStatus } } = await getPublicInfo();

                    if (paymentStatus === PAYMENT_STATUS.NEW) {
                        resolve(await handlePaymentRequestStatus(retryCount + 1));
                    }

                    resolve(paymentStatus);
                } catch (error) {
                    reject(error);
                }
            }, DELAY_HANDLE_WAITING_STATUS);
        });
    };

    parentWindow.on(Event.MESSAGE, async (message) => {
        const messageType = message.command;

        if (messageType === 'success') {
            paymentStatusRedirect.redirectToSuccess(paymentRequest.getId());

            return;
        }

        if (messageType === 'error') {
            paymentStatusRedirect.redirectToError(paymentRequest.getId());

            return;
        }

        if (messageType === 'getResult') {
            try {
                const paymentStatus = await handlePaymentRequestStatus();

                if (
                    [
                        PAYMENT_STATUS.SUCCESS,
                        PAYMENT_STATUS.INSTANTLY_REFUNDED,
                    ].includes(paymentStatus)
                ) {
                    paymentStatusRedirect.redirectToSuccess(paymentRequest.getId());

                    return;
                }

                if (paymentStatus === PAYMENT_STATUS.PROCESSING) {
                    window.location.reload();

                    return;
                }

                paymentStatusRedirect.redirectToError(paymentRequest.getId());
            } catch (error) {
                captureException(error);

                paymentStatusRedirect.redirectToError(paymentRequest.getId());
            }

            return;
        }

        if (messageType === 'recreatePayment') {
            await recreatePaymentHandler();
        }
    });
};
