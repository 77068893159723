const getSame = value => value;

const arrayToOptions = (array, {
    getValue = getSame,
    getLabel = getSame,
} = {}) => array.map(value => ({
    value: getValue(value),
    label: getLabel(value),
}));

export default arrayToOptions;
