import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import DebounceLoader from '../DebounceLoader';
import ModalWrap from '../ModalWrap/ModalWrap';
import FullPageWrapWithPaymentInformation from '../FullPageWrap/FullPageWrapWithPaymentInformation';
import { getCurrentRequest } from '../../../selectors/payment/get-current-request';
import { getHasPaymentAmount } from '../../../utils/getHasPaymentAmount';
import { getHasPayerEmail } from '../../../utils/getHasPayerEmail';
import { isLoading as isLoadingSelector } from '../../../selectors/is-loading';

export function CheckoutView({ children }) {
    const isLoading = useSelector(state => isLoadingSelector(state, 'checkout'));
    const isModal = useMemo(() => window.self !== window.top, [window]);
    const paymentRequest = useSelector(state => getCurrentRequest(state));
    const hasPaymentAmount = useMemo(getHasPaymentAmount(paymentRequest), [paymentRequest]);
    const hasPayerEmail = useMemo(getHasPayerEmail(paymentRequest), [paymentRequest]);

    return (
        <DebounceLoader loaded={!isLoading}>
            {(isModal && !isLoading) && (
                <ModalWrap childWindow={window.childWindow}>
                    {children}
                </ModalWrap>
            )}

            {(!isModal && !isLoading) && (
                <FullPageWrapWithPaymentInformation
                    hasPaymentAmount={hasPaymentAmount}
                    hasPayerEmail={hasPayerEmail}
                >
                    {children}
                </FullPageWrapWithPaymentInformation>
            )}
        </DebounceLoader>
    );
}

CheckoutView.propTypes = {
    children: PropTypes.node.isRequired,
};
