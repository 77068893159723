import isEmpty from 'lodash/isEmpty';

const filterMethodsByCountry = (methods, country) => {
    if (isEmpty(methods)) {
        return [];
    }

    return methods.getItems().filter((method) => {
        const countries = method.getCountries();

        if (isEmpty(countries)) {
            return true;
        }
        return countries.includes(String(country.value).toUpperCase());
    });
};

export default filterMethodsByCountry;
