import { handleActions } from 'redux-actions';

import { FETCH_BUSINESS_SUCCESS } from '../../actions/business/fetch-business';

export default handleActions({
    [FETCH_BUSINESS_SUCCESS]: (state, { payload }) => ({
        ...state,
        ...payload,
    }),
}, {});
