import { getCountriesClient } from '../services/api-client';
import { createRoutine, fetchAction } from '../store/utils';
import { middlewareAddLanguage } from '../services/middlewares';
import { getCountriesLocale } from '../selectors/countries';

export const fetchCountriesRoutine = createRoutine('FETCH_COUNTRIES');

export const fetchCountries = currentLocale => (dispatch, getState) => {
    const client = getCountriesClient({
        middleware: [middlewareAddLanguage(currentLocale)],
    });
    const getCountries = () => client.getCountries();
    const prepareResult = ({ data: { countries } }) => ({
        locale: currentLocale,
        all: countries,
    });

    if (getCountriesLocale(getState()) !== currentLocale) {
        fetchAction(fetchCountriesRoutine, getCountries, prepareResult)(dispatch);
    }
};
