import { Entity } from '@paysera/http-client-common';

class Logo extends Entity {
    constructor(data = {}) {
        super(data);
    }

    /**
     * @return {string|null}
     */
    getUrl() {
        return this.get('url');
    }

    /**
     * @param {string} url
     */
    setUrl(url) {
        this.set('url', url);
    }
}

export default Logo;
