import { handleActions } from 'redux-actions';

import { fetchCountriesRoutine } from '../actions/countries';

export default handleActions({
    [fetchCountriesRoutine.SUCCESS]: (state, { payload }) => ({
        ...state,
        ...payload,
    }),
}, {});
