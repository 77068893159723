import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Parent } from 'paysera-across-windows';
import { PaymentRequestResponse } from '@paysera/checkout-client';
import { useSelector } from 'react-redux';

import { getCurrentLocale } from '../../../selectors/get-current-locale';

import { createIframe } from './utils/cardPaymentIframe';
import { iframeEventListener } from './utils/iframeEventListener';
import { useRecreatePayment } from '../../../hooks/useRecreatePayment';
import { usePaymentStatusRedirect } from '../../../hooks/usePaymentStatusRedirect';

import { usePublicClientService } from '../CheckoutPage/hooks/usePublickClientService';

const ReactForm = ({ paymentRequest, iframeUrl }) => {
    const iframeWrapperRef = useRef(null);
    const currentLocale = useSelector(state => getCurrentLocale(state));
    const paymentStatusRedirect = usePaymentStatusRedirect();
    const { getPublicInfo } = usePublicClientService(paymentRequest.getId());

    const parentWindow = useMemo(() => new Parent(window, '*'), []);

    const recreatePaymentHandler = useRecreatePayment(
        paymentRequest.getId(),
        paymentRequest.getCancelUrl(),
    );

    useEffect(() => {
        const fetchData = async () => {
            createIframe({
                paymentRequestHash: paymentRequest.getId(),
                currentLocale,
                iframeWrapper: iframeWrapperRef,
                parentWindow,
                iframeUrl,
            });

            await iframeEventListener({
                parentWindow,
                paymentRequest,
                paymentStatusRedirect,
                getPublicInfo,
                recreatePaymentHandler,
            });
        };

        fetchData();
    }, [currentLocale, paymentRequest]);

    return (
        <div id="credit-card-wizard" data-testid='credit-card-wizard'>
            <div ref={iframeWrapperRef} />
        </div>
    );
};

ReactForm.propTypes = {
    paymentRequest: PropTypes.instanceOf(PaymentRequestResponse).isRequired,
    iframeUrl: PropTypes.string.isRequired,
};

export default ReactForm;
