import isEmpty from 'lodash/isEmpty';
import { PaymentRequestPublic } from '@paysera/checkout-client';

export const getHasPayerEmail = paymentRequest => () => {
    if (isEmpty(paymentRequest.getData())) return false;

    const paymentRequestPublic = new PaymentRequestPublic(paymentRequest.getData());

    return paymentRequestPublic.getPayer().isIsEmailPresent();
};
