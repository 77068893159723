import { Entity } from '@paysera/http-client-common';

class BasicInformation extends Entity {
    constructor(data = {}) {
        super(data);
    }

    /**
     * @return {string}
     */
    getLanguage() {
        return this.get('language');
    }

    /**
     * @param {string} language
     */
    setLanguage(language) {
        this.set('language', language);
    }

    /**
     * @return {string}
     */
    getTitle() {
        return this.get('title');
    }

    /**
     * @param {string} title
     */
    setTitle(title) {
        this.set('title', title);
    }

    /**
     * @return {string}
     */
    getDescription() {
        return this.get('description');
    }

    /**
     * @param {string} description
     */
    setDescription(description) {
        this.set('description', description);
    }

    /**
     * @return {string|null}
     */
    getDelivery() {
        return this.get('delivery');
    }

    /**
     * @param {string} delivery
     */
    setDelivery(delivery) {
        this.set('delivery', delivery);
    }

    /**
     * @return {string|null}
     */
    getRefund() {
        return this.get('refund');
    }

    /**
     * @param {string} refund
     */
    setRefund(refund) {
        this.set('refund', refund);
    }
}

export default BasicInformation;
