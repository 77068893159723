import { Result } from '@paysera/http-client-common';
import SmsReceiver from './SmsReceiver';

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["createItem"] }] */
class SmsReceiverResult extends Result {
    /**
     * @param {Array} data
     * @returns {SmsReceiver}
     */
    createItem(data) {
        return new SmsReceiver(data);
    }
}

export default SmsReceiverResult;
