import React, {
    useMemo,
    useState,
    memo,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { PaymentRequestMethodResult } from '@paysera/checkout-client';

import MethodGroups from './common/MethodGroups';
import CountrySelect from './common/CountrySelect';
import { parseToGroup, filterMethodsByCountry } from './utils';

const Methods = ({
    countriesOptions, methods,
}) => {
    const [country, setCountry] = useState(countriesOptions[0]);

    const filteredMethods = useMemo(() => filterMethodsByCountry(methods, country), [methods, country]);

    const groups = useMemo(
        () => parseToGroup(filteredMethods, method => method.getGroupKey()),
        [filteredMethods],
    );

    return (
        <>
            <CountrySelect
                country={country}
                setCountry={setCountry}
                countries={countriesOptions}
            />

            {!isEmpty(groups) && (
                <MethodGroups
                    groups={groups}
                />
            )}
        </>
    );
};

Methods.propTypes = {
    countriesOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })).isRequired,
    methods: PropTypes.instanceOf(PaymentRequestMethodResult).isRequired,
};

export default memo(Methods);
