import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from '@paysera/business-client';

import styles from './BusinessLogo.module.scss';

const BusinessLogo = ({ businessLogo, className }) => (
    businessLogo !== null && (
        <div className={`${styles.businessLogo} ${className}`}>
            <img src={businessLogo.getUrl()} alt="" />
        </div>
    )
);

BusinessLogo.propTypes = {
    businessLogo: PropTypes.instanceOf(Logo),
    className: PropTypes.string,
};

BusinessLogo.defaultProps = {
    businessLogo: null,
    className: '',
};

export default BusinessLogo;
