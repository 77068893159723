import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { PaymentRequestMethod } from '@paysera/checkout-client';
import { useSelector } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import styles from './Method.module.scss';
import arrowRight from '../../../../images/arrow-right.png';

const Method = ({
    method,
    onClick,
}) => {
    const currentLanguage = useSelector(state => getActiveLanguage(state.localize).code);
    const basicInformation = method.getBasicInformation();
    const basicInformationLocale = basicInformation.find(
        information => information.getData().language_code === currentLanguage,
    );

    return (
        <div className={styles.container} onClick={() => onClick(method)}>
            <div className={styles.logoWrapper}>
                <img src={method.getLogoUrl()} alt={method.getData().method_key} />
            </div>
            <div className={styles.textWrapper}>
                <span>{basicInformationLocale.getTitle()}</span>
            </div>
            <div className={styles.arrowWrapper}>
                <img src={arrowRight} alt="arrow" />
            </div>
        </div>
    );
};

Method.propTypes = {
    method: PropTypes.instanceOf(PaymentRequestMethod).isRequired,
    onClick: PropTypes.func.isRequired,
};

export default memo(Method);
