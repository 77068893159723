import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import rootReducer from '../reducers';

export const history = createBrowserHistory();

const configureStore = (initialState) => {
    const routerMiddleware = createRouterMiddleware(history);
    const middlewares = [thunk, routerMiddleware];

    // eslint-disable-next-line no-underscore-dangle
    const composeEnhancers = process.env.NODE_ENV === 'production' ? compose : (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose);

    const store = createStore(
        rootReducer(history),
        initialState,
        composeEnhancers(applyMiddleware(...middlewares)),
    );

    return store;
};

export default configureStore;
