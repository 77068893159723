import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import { useSelector } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import { getCurrentLocale } from '../../selectors/get-current-locale';

import activeLanguageShape from '../../shapes/activeLanguageShape';
import init from '../../i18n/translations/i18next/init';
import config from '../../config';

const { supportedLocales } = config;
const defaultLanguage = config.defaultLocale;

function LocaleProviderWrapper({
    initialize, setActiveLanguage, activeLanguage, children,
}) {
    const locale = useSelector(state => getCurrentLocale(state));

    useEffect(() => {
        initialize({
            languages: supportedLocales,
            options: {
                renderToStaticMarkup,
                renderInnerHtml: true,
                defaultLanguage,
            },
        });
    }, []);

    useEffect(() => {
        init(locale);
        setActiveLanguage(locale);
    }, [locale]);

    return activeLanguage && children;
}

LocaleProviderWrapper.propTypes = {
    initialize: PropTypes.func.isRequired,
    setActiveLanguage: PropTypes.func.isRequired,
    activeLanguage: PropTypes.shape(activeLanguageShape),
    children: PropTypes.node.isRequired,
};

LocaleProviderWrapper.defaultProps = {
    activeLanguage: null,
};

export default withLocalize(LocaleProviderWrapper);
