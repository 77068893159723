import { captureException } from '@sentry/browser';
import { createAction } from 'redux-actions';
import storage from 'local-storage-fallback';

import { fetchPaymentRequest } from '../payment/fetch-payment-request';
import { fetchBusinessLogo } from '../business/fetch-business-logo';
import { getCurrentRequest } from '../../selectors/payment/get-current-request';
import { getCurrentLocale } from '../../selectors/get-current-locale';
import { changeLocale } from '../change-locale';

export const CHECKOUT_LOAD_START = 'checkout/CHECKOUT_LOAD_START';
export const CHECKOUT_LOAD_SUCCESS = 'checkout/CHECKOUT_LOAD_SUCCESS';
export const CHECKOUT_LOAD_ERROR = 'checkout/CHECKOUT_LOAD_ERROR';

const checkoutLoadStart = createAction(CHECKOUT_LOAD_START);
const checkoutLoadSuccess = createAction(CHECKOUT_LOAD_SUCCESS);
const checkoutLoadError = createAction(CHECKOUT_LOAD_ERROR);

export const loadCheckout = paymentRequestHash => async (dispatch, getState) => {
    dispatch(checkoutLoadStart());

    const locale = getCurrentLocale(getState());

    try {
        await dispatch(fetchPaymentRequest(paymentRequestHash, locale));
        const paymentRequest = getCurrentRequest(getState());

        if (paymentRequest.getLocale() && storage.getItem('locale') === null) {
            await dispatch(changeLocale(paymentRequest.getLocale()));
        }

        await dispatch(fetchBusinessLogo(paymentRequest.getBusinessId()));

        await dispatch(checkoutLoadSuccess());
    } catch (error) {
        captureException(error);
        await dispatch(checkoutLoadError());
    }
};
