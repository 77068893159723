import React, {
    useEffect,
    useMemo,
    memo,
} from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import DebounceLoader from '../DebounceLoader';
import { fetchPaymentMethods as fetchPaymentMethodsAction } from '../../../actions/payment/methods';
import { getCurrentRequestId } from '../../../selectors/payment/get-current-request';
import { isLoading as isLoadingSelector } from '../../../selectors/is-loading';
import { getPaymentMethods } from '../../../selectors/payment/get-payment-methods';
import {
    arrayToOptions,
    createCountry,
    filterCountriesByMethods,
} from './utils';
import Methods from './Methods';
import { fetchCountries as fetchCountriesAction } from '../../../actions/countries';
import { getCurrentLocale } from '../../../selectors/get-current-locale';
import { getAllCountries } from '../../../selectors/countries';
import { OTHER_COUNTRY_CODE } from './config/settings';

const MethodsContainer = () => {
    const dispatch = useDispatch();
    const {
        fetchPaymentMethods,
        fetchCountries,
    } = bindActionCreators({
        fetchPaymentMethods: fetchPaymentMethodsAction,
        fetchCountries: fetchCountriesAction,
    }, dispatch);
    const {
        isLoading,
        paymentRequestId,
        methods,
        currentLocale,
        countries,
    } = useSelector(state => ({
        isLoading: isLoadingSelector(state, 'payment_methods') || false,
        paymentRequestId: getCurrentRequestId(state) || '',
        methods: getPaymentMethods(state),
        currentLocale: getCurrentLocale(state),
        countries: getAllCountries(state) || [],
    }));
    const { t } = useTranslation('checkout-frontend');
    const countriesOptions = useMemo(() => {
        const filteredCountries = filterCountriesByMethods(methods, countries);

        return arrayToOptions(
            [...filteredCountries, createCountry({ name: t('country_other'), alpha2Code: OTHER_COUNTRY_CODE })],
            {
                getValue: country => country.getAlpha2Code(),
                getLabel: country => country.getName(),
            },
        );
    }, [methods, t]);

    useEffect(() => {
        if (paymentRequestId) {
            fetchPaymentMethods(paymentRequestId);
        }
    }, [paymentRequestId]);

    useEffect(() => {
        if (currentLocale) {
            fetchCountries(currentLocale);
        }
    }, [currentLocale]);

    const canRender = useMemo(() => isEmpty(methods) || isEmpty(countries), [countries, methods]);

    return (
        <DebounceLoader loaded={!isLoading}>
            {isLoading || canRender ? (
                <></>
            ) : (
                <Methods
                    countriesOptions={countriesOptions}
                    methods={methods}
                />
            )}
        </DebounceLoader>
    );
};

export default memo(MethodsContainer);
