import React from 'react';
import PropTypes from 'prop-types';

import styles from './Modal.module.scss';

const HeaderLabel = ({ children, className }) => (
    <div className={`${styles.headerLabel} ${className}`}>
        {children}
    </div>
);

HeaderLabel.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

HeaderLabel.defaultProps = {
    className: '',
};

export default HeaderLabel;
