import { handleActions } from 'redux-actions';

import {
    CHECKOUT_LOAD_START,
    CHECKOUT_LOAD_SUCCESS,
    CHECKOUT_LOAD_ERROR,
} from '../actions/checkout/load-checkout';

import {
    FETCH_PAYMENT_REQUEST_REQUEST,
    FETCH_PAYMENT_REQUEST_SUCCESS,
    FETCH_PAYMENT_REQUEST_FAILED,
} from '../actions/payment/fetch-payment-request';

import {
    FETCH_BUSINESS_REQUEST,
    FETCH_BUSINESS_SUCCESS,
    FETCH_BUSINESS_FAILED,
} from '../actions/business/fetch-business';

import {
    FETCH_BUSINESS_LOGO_REQUEST,
    FETCH_BUSINESS_LOGO_SUCCESS,
    FETCH_BUSINESS_LOGO_FAILED,
} from '../actions/business/fetch-business-logo';

import {
    START_PAYMENT_REQUEST,
    START_PAYMENT_SUCCESS,
    START_PAYMENT_FAILED,
} from '../actions/payment/start-payment';

import {
    FETCH_PAYMENT_METHODS_REQUEST,
    FETCH_PAYMENT_METHODS_SUCCESS,
    FETCH_PAYMENT_METHODS_FAILED,
} from '../actions/payment/methods';

const setIsLoading = (state, entity, isLoading) => ({ ...state, [entity]: isLoading });

export default handleActions({
    [CHECKOUT_LOAD_START]: state => setIsLoading(state, 'checkout', true),
    [CHECKOUT_LOAD_SUCCESS]: state => setIsLoading(state, 'checkout', false),
    [CHECKOUT_LOAD_ERROR]: state => setIsLoading(state, 'checkout', false),

    [FETCH_PAYMENT_REQUEST_REQUEST]: state => setIsLoading(state, 'payment_request', true),
    [FETCH_PAYMENT_REQUEST_SUCCESS]: state => setIsLoading(state, 'payment_request', false),
    [FETCH_PAYMENT_REQUEST_FAILED]: state => setIsLoading(state, 'payment_request', false),

    [FETCH_BUSINESS_REQUEST]: state => setIsLoading(state, 'business', true),
    [FETCH_BUSINESS_SUCCESS]: state => setIsLoading(state, 'business', false),
    [FETCH_BUSINESS_FAILED]: state => setIsLoading(state, 'business', false),

    [FETCH_BUSINESS_LOGO_REQUEST]: state => setIsLoading(state, 'business_logo', true),
    [FETCH_BUSINESS_LOGO_SUCCESS]: state => setIsLoading(state, 'business_logo', false),
    [FETCH_BUSINESS_LOGO_FAILED]: state => setIsLoading(state, 'business_logo', false),

    [START_PAYMENT_REQUEST]: state => setIsLoading(state, 'start_payment', true),
    [START_PAYMENT_SUCCESS]: state => setIsLoading(state, 'start_payment', false),
    [START_PAYMENT_FAILED]: state => setIsLoading(state, 'start_payment', false),

    [FETCH_PAYMENT_METHODS_REQUEST]: state => setIsLoading(state, 'payment_methods', true),
    [FETCH_PAYMENT_METHODS_SUCCESS]: state => setIsLoading(state, 'payment_methods', false),
    [FETCH_PAYMENT_METHODS_FAILED]: state => setIsLoading(state, 'payment_methods', false),
}, {});
