import { captureException } from '@sentry/browser';
import { createAction } from 'redux-actions';
import { getBusinessClient } from '../../services/api-client';

export const FETCH_BUSINESS_LOGO_REQUEST = 'business/FETCH_BUSINESS_LOGO_REQUEST';
export const FETCH_BUSINESS_LOGO_SUCCESS = 'business/FETCH_BUSINESS_LOGO_SUCCESS';
export const FETCH_BUSINESS_LOGO_FAILED = 'business/FETCH_BUSINESS_LOGO_FAILED';

const fetchBusinessLogoRequest = createAction(FETCH_BUSINESS_LOGO_REQUEST);
const fetchBusinessLogoSuccess = createAction(FETCH_BUSINESS_LOGO_SUCCESS);
const fetchBusinessLogoFailed = createAction(FETCH_BUSINESS_LOGO_FAILED);

export const fetchBusinessLogo = hash => async (dispatch) => {
    dispatch(fetchBusinessLogoRequest());

    const client = getBusinessClient();

    try {
        const { data } = await client.getBusinessLogo(hash);
        await dispatch(fetchBusinessLogoSuccess(data));
    } catch (error) {
        if (error.response.status !== 404 && error.response.status !== 401) {
            captureException(error);
        }

        await dispatch(fetchBusinessLogoFailed());
    }
};
