import React, { memo } from 'react';
import PropTypes from 'prop-types';

import MethodGroup from './MethodGroup';

const MethodGroups = ({ groups }) => Object.entries(groups).map(([groupKey, methods]) => (
    <MethodGroup
        key={groupKey}
        groupKey={groupKey}
        methods={methods}
    />
));

MethodGroups.propTypes = {
    groups: PropTypes.objectOf(PropTypes.any),
};

export default memo(MethodGroups);
