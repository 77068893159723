import storage from 'local-storage-fallback';
import { setActiveLanguage } from 'react-localize-redux';

import { normalizeLocale } from '../i18n/translations/normalizeLocale';

export const changeLocale = locale => (dispatch) => {
    const normalizedLocale = normalizeLocale(locale);

    dispatch(setActiveLanguage(normalizedLocale));

    storage.setItem('locale', normalizeLocale(normalizedLocale));
};
