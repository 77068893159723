import { Entity } from '@paysera/http-client-common';

class Contact extends Entity {
    constructor(data = {}) {
        super(data);
    }

    /**
     * @return {string|null}
     */
    getId() {
        return this.get('id');
    }

    /**
     * @param {string} id
     */
    setId(id) {
        this.set('id', id);
    }

    /**
     * @return {string}
     */
    getName() {
        return this.get('name');
    }

    /**
     * @param {string} name
     */
    setName(name) {
        this.set('name', name);
    }

    /**
     * @return {string|null}
     */
    getEmail() {
        return this.get('email');
    }

    /**
     * @param {string} email
     */
    setEmail(email) {
        this.set('email', email);
    }

    /**
     * @return {string|null}
     */
    getPhone() {
        return this.get('phone');
    }

    /**
     * @param {string} phone
     */
    setPhone(phone) {
        this.set('phone', phone);
    }

    /**
     * @return {string|null}
     */
    getAddress() {
        return this.get('address');
    }

    /**
     * @param {string} address
     */
    setAddress(address) {
        this.set('address', address);
    }

    /**
     * @return {string|null}
     */
    getSkype() {
        return this.get('skype');
    }

    /**
     * @param {string} skype
     */
    setSkype(skype) {
        this.set('skype', skype);
    }

    /**
     * @return {string|null}
     */
    getOther() {
        return this.get('other');
    }

    /**
     * @param {string} other
     */
    setOther(other) {
        this.set('other', other);
    }

    /**
     * @return {string|null}
     */
    getContactsUrl() {
        return this.get('contacts_url');
    }

    /**
     * @param {string} contactsUrl
     */
    setContactsUrl(contactsUrl) {
        this.set('contacts_url', contactsUrl);
    }

    /**
     * @return {string|null}
     */
    getCountry() {
        return this.get('country');
    }

    /**
     * @param {string} country
     */
    setCountry(country) {
        this.set('country', country);
    }

    /**
     * @return {string|null}
     */
    getLanguage() {
        return this.get('language');
    }

    /**
     * @param {string} language
     */
    setLanguage(language) {
        this.set('language', language);
    }
}

export default Contact;
