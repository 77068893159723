import { useEffect } from 'react';
import { captureException } from '@sentry/browser';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { loadCheckout } from '../actions/checkout/load-checkout';

export function useCheckoutLoad() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        const resolveLoadCheckout = async () => {
            try {
                await dispatch(loadCheckout(params.paymentRequestHash));
            } catch (error) {
                captureException(error);
                navigate('/not-found');
            }
        };

        resolveLoadCheckout();
    }, []);
}
