import React from 'react';
import PropTypes from 'prop-types';

import ModalBody from './ModalBody';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';

import styles from './Modal.module.scss';

const ModalWrap = ({
    childWindow,
    children,
}) => (
    <div className={`${styles.modalWrap} checkout-modal`}>
        <div className={styles.modal}>
            <ModalHeader childWindow={childWindow} />

            <ModalBody>
                {children}
            </ModalBody>

            <ModalFooter />
        </div>
    </div>
);

ModalWrap.propTypes = {
    children: PropTypes.node.isRequired,
    childWindow: PropTypes.object.isRequired,
};

export default ModalWrap;
