import React from 'react';
import PropTypes from 'prop-types';
import { PaymentRequestResponse } from '@paysera/checkout-client';

import ReactForm from './ReactForm';
import WizardHeader from '../WizardHeader';

const CreditCardWizard = (props) => {
    const { paymentRequest, iframeUrl } = props;

    return (
        <div className="paysera-checkout-modal">
            <WizardHeader />
            <ReactForm paymentRequest={paymentRequest} iframeUrl={iframeUrl} />
        </div>
    );
};

CreditCardWizard.propTypes = {
    iframeUrl: PropTypes.string.isRequired,
    paymentRequest: PropTypes.instanceOf(PaymentRequestResponse).isRequired,
};

export default CreditCardWizard;
