import { handleActions } from 'redux-actions';

import { START_PAYMENT_SUCCESS } from '../../actions/payment/start-payment';

export default handleActions({
    [START_PAYMENT_SUCCESS]: (state, { payload }) => ({
        ...state,
        ...payload,
    }),
}, {});
