import React from 'react';

import creditCardTypesImage from './card-types.png';
import creditCardIconImage from './card-icon.png';

import styles from './WizardHeader.module.scss';

export function WizardHeader() {
    return (
        <div className={`${styles.blockHeaderWrapper} card-logos-row`}>
            <div className={styles.blockCardIcon}>
                <img src={creditCardIconImage} alt="" />
                <img src={creditCardTypesImage} alt="" />
            </div>
        </div>
    );
}

export default WizardHeader;
