import React, { useMemo } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import buyerIcon from '../buyer-icon.png';
import styles from './InformationBlock.module.scss';

import CheckoutTextField from './Fields/CheckoutTextField';
import SubmitBlock from './SubmitBlock';
import { required, email } from '../../../formValidationRules';
import InformationBlock from '../InformationBlock';

function MissingPayerBlock(props) {
    const {
        submitting, invalid, pristine,
    } = props;

    const { t } = useTranslation('checkout-frontend');

    const validateRequired = useMemo(() => required(t), []);
    const validateEmail = useMemo(() => email(t), []);

    return (
        <InformationBlock headerLabel={t('payer')} headerImage={buyerIcon} toggleAble>
            <div className={styles.missingBlock}>
                <Field
                    name="payerEmail"
                    type="text"
                    component={CheckoutTextField}
                    label={t('email_input_label')}
                    validate={[validateRequired, validateEmail]}
                />
                <SubmitBlock submitting={submitting} invalid={invalid} pristine={pristine} />
            </div>
        </InformationBlock>
    );
}

MissingPayerBlock.propTypes = {
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
};

export default MissingPayerBlock;
