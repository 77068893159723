import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const AppHead = () => {
    const { t } = useTranslation('checkout-frontend');
    return (
        <Helmet key="head">
            <title>{t('checkout_title')}</title>
        </Helmet>
    );
};

export default AppHead;
