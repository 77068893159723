import BasicInformation from './BasicInformation';
import PublicUrl from './PublicUrl';
import { Entity } from '@paysera/http-client-common';

class Business extends Entity {
    constructor(data = {}) {
        super(data);
    }

    /**
     * @return {string|null}
     */
    getId() {
        return this.get('id');
    }

    /**
     * @param {string} id
     */
    setId(id) {
        this.set('id', id);
    }

    /**
     * @return {string}
     */
    getOwnerId() {
        return this.get('owner_id');
    }

    /**
     * @param {string} ownerId
     */
    setOwnerId(ownerId) {
        this.set('owner_id', ownerId);
    }

    /**
     * @return {string}
     */
    getTitle() {
        return this.get('title');
    }

    /**
     * @param {string} title
     */
    setTitle(title) {
        this.set('title', title);
    }

    /**
     * @return {string|null}
     */
    getUniqueIdentifier() {
        return this.get('unique_identifier');
    }

    /**
     * @param {string} uniqueIdentifier
     */
    setUniqueIdentifier(uniqueIdentifier) {
        this.set('unique_identifier', uniqueIdentifier);
    }

    /**
     * @return {string}
     */
    getAccountNumber() {
        return this.get('account_number');
    }

    /**
     * @param {string} accountNumber
     */
    setAccountNumber(accountNumber) {
        this.set('account_number', accountNumber);
    }

    /**
     * @return {string|null}
     */
    getCurrencyExchange() {
        return this.get('currency_exchange');
    }

    /**
     * @param {string} currencyExchange
     */
    setCurrencyExchange(currencyExchange) {
        this.set('currency_exchange', currencyExchange);
    }

    /**
     * @return {Array.<BasicInformation>}
     */
    getBasicInformation() {
        let data = this.get('basic_information');
        if (data === null) {
            return [];
        }

        let collection = [];
        for (let value of data) {
            collection.push(new BasicInformation(value));
        }

        return collection;
    }

    /**
     * @param {Array.<BasicInformation>} basicInformation
     */
    setBasicInformation(basicInformation) {
        let data = [];
        for (let entity of basicInformation) {
            data.push(entity.getData());
        }
        this.set('basic_information', data);
    }

    /**
     * @return {Array.<PublicUrl>}
     */
    getPublicUrls() {
        let data = this.get('public_urls');
        if (data === null) {
            return [];
        }

        let collection = [];
        for (let value of data) {
            collection.push(new PublicUrl(value));
        }

        return collection;
    }

    /**
     * @param {Array.<PublicUrl>} publicUrls
     */
    setPublicUrls(publicUrls) {
        let data = [];
        for (let entity of publicUrls) {
            data.push(entity.getData());
        }
        this.set('public_urls', data);
    }

    /**
     * @return {Array.<string>|null}
     */
    getLanguages() {
        return this.get('languages');
    }

    /**
     * @param {Array.<string>} languages
     */
    setLanguages(languages) {
        this.set('languages', languages);
    }

    /**
     * @return {Array.<string>|null}
     */
    getCategories() {
        return this.get('categories');
    }

    /**
     * @param {Array.<string>} categories
     */
    setCategories(categories) {
        this.set('categories', categories);
    }

    /**
     * @return {string|null}
     */
    getStatus() {
        return this.get('status');
    }

    /**
     * @param {string} status
     */
    setStatus(status) {
        this.set('status', status);
    }

    /**
     * @return {Number|null}
     */
    getCreatedAt() {
        return this.get('created_at');
    }

    /**
     * @param {Number} createdAt
     */
    setCreatedAt(createdAt) {
        this.set('created_at', createdAt);
    }
}

export default Business;
