import validator from 'validator';

export const required = t => value => (
    value || typeof value === 'number' ? undefined : t('required_field_validation')
);

export const decimal = t => value => (
    (validator.isDecimal(value, { decimal_digits: '0,2' }) && parseFloat(value) > 0) ? undefined : t('currency_field_validation')
);

export const email = t => value => (
    (value && validator.isEmail(value)) ? undefined : t('email_field_validation')
);
