class TranslationProxyClient {
    constructor(client) {
        this.client = client;
    }

    getTranslationsForDomainByLanguage(domain, language) {
        return this.client.get(
            `/public/js_translations/phrases/${language}/${domain}.json`,
        );
    }
}

export default TranslationProxyClient;
