import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

const filterCountriesByMethods = (methods, countries) => {
    if (isEmpty(methods)) {
        return [];
    }
    const allCountriesFromMethods = uniq(methods.getItems().reduce((accum, method) => {
        const methodCountries = method.getCountries();
        return isEmpty(methodCountries) ? accum : [...accum, ...methodCountries];
    }, []));

    return countries.filter(country => allCountriesFromMethods.includes(country.getAlpha2Code()));
};

export default filterCountriesByMethods;
