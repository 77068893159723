import React from 'react';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

import styles from './FullPageHeader.module.scss';

const FullPageHeader = () => (
    <header className={`${styles.header} py-3`}>
        <div className="container">
            <div className="d-flex align-items-center justify-content-end">
                <LanguageSelector className={styles['language-selector']} />
            </div>
        </div>
    </header>
);

export default FullPageHeader;
