import { Entity } from '@paysera/http-client-common';

class SmsReceiver extends Entity {
    constructor(data = {}) {
        super(data);
    }

    /**
     * @return {string|null}
     */
    getId() {
        return this.get('id');
    }

    /**
     * @param {string} id
     */
    setId(id) {
        this.set('id', id);
    }

    /**
     * @return {string|null}
     */
    getBusinessId() {
        return this.get('business_id');
    }

    /**
     * @param {string} businessId
     */
    setBusinessId(businessId) {
        this.set('business_id', businessId);
    }

    /**
     * @return {string|null}
     */
    getPhoneNumber() {
        return this.get('phone_number');
    }

    /**
     * @param {string} phoneNumber
     */
    setPhoneNumber(phoneNumber) {
        this.set('phone_number', phoneNumber);
    }

    /**
     * @return {string|null}
     */
    getInfoTypeToReceive() {
        return this.get('info_type_to_receive');
    }

    /**
     * @param {string} infoTypeToReceive
     */
    setInfoTypeToReceive(infoTypeToReceive) {
        this.set('info_type_to_receive', infoTypeToReceive);
    }
}

export default SmsReceiver;
