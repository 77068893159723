import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PaymentRequestResponse } from '@paysera/checkout-client';

import BusinessLogo from './PaymentInformation/BusinessLogo';
import SellerBlock from './PaymentInformation/InformationBlock/SellerBlock';
import PriceBlock from './PaymentInformation/InformationBlock/PriceBlock';

import { getBusinessLogo } from '../../../selectors/business/get-business-logo';

const PaymentInformation = ({ paymentRequest }) => {
    const businessLogo = useSelector(state => getBusinessLogo(state));

    return (
        <>
            <BusinessLogo businessLogo={businessLogo} />
            <PriceBlock
                amount={paymentRequest.getPrice().getAmount()}
                currency={paymentRequest.getPrice().getCurrency()}
                orderId={paymentRequest.getOrderId()}
                description={paymentRequest.getDescription()}
            />
            <SellerBlock paymentRequest={paymentRequest} />
        </>
    );
};

PaymentInformation.propTypes = {
    paymentRequest: PropTypes.instanceOf(PaymentRequestResponse).isRequired,
};

export default PaymentInformation;
