import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import cartIcon from '../cart-icon.png';
import styles from './InformationBlock.module.scss';

import InlinePrice from '../../../InlinePrice';
import BlockRow from './BlockRow';
import InformationBlock from '../InformationBlock';

const PriceBlock = ({
    orderId,
    amount,
    currency,
    description,
}) => {
    const { t } = useTranslation('checkout-frontend');
    const inlinePrice = (
        <InlinePrice
            amount={amount}
            currency={currency}
            className={styles.inlinePrice}
        />
    );

    return (
        <InformationBlock
            headerLabel={`${t('order_no')}:`}
            headerValue={orderId}
            headerImage={cartIcon}
        >
            <div className={styles.informationBlock}>
                <BlockRow
                    label={`${t('amount')}:`}
                    value={inlinePrice}
                />
            </div>
            {
                description && (
                    <BlockRow
                        label={`${t('orderDescription')}:`}
                        value={description}
                    />
                )
            }
        </InformationBlock>
    );
};

PriceBlock.propTypes = {
    orderId: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    description: PropTypes.string,
};

PriceBlock.defaultProps = {
    description: null,
};

export default memo(PriceBlock);
