export const parseTimestampToString = (timestamp) => {
    if (timestamp <= 0) {
        return '0:00';
    }

    const minutes = Math.floor(timestamp / 60);
    const remainingSeconds = timestamp % 60;

    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};
