import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import styles from './Loader.module.scss';

const Loader = ({
    loaded, children, message, backgroundStyle,
}) => (
    <div className='loader-container'>
        <div className={!loaded ? styles.childrenContainer : null}>{children}</div>
        {!loaded && (
            <div className={styles.spinnerContainer} style={{ ...backgroundStyle }}>
                {message || <FontAwesomeIcon icon={faSpinner} size='2x' pulse />}
            </div>
        )}
    </div>
);

Loader.propTypes = {
    loaded: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    message: PropTypes.node,
    backgroundStyle: PropTypes.shape({}),
};

Loader.defaultProps = {
    message: null,
    backgroundStyle: null,
};

export default Loader;
