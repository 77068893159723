import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useRecreatePayment } from '../../hooks/useRecreatePayment';
import { useCheckoutLoad } from '../../hooks/useCheckoutLoad';
import { CheckoutView } from '../Common/CheckoutView/CheckoutView';
import errorIcon from '../../images/ico-alert.png';
import WizardHeader from '../Common/WizardHeader';
import { getCurrentRequest } from '../../selectors/payment/get-current-request';

import './ErrorPage.scss';

function ErrorPage() {
    const { t } = useTranslation('credit-card-checkout');
    useCheckoutLoad();

    const paymentRequest = useSelector(state => getCurrentRequest(state));

    const recreatePaymentHandler = useRecreatePayment(
        paymentRequest.getId(),
        paymentRequest.getCancelUrl(),
    );

    return (
        <CheckoutView>
            <WizardHeader />
            <div className="error-wrapper">
                <img src={errorIcon} alt="" />
                <div className="error-message">{t('error_message')}</div>
                <button type="button" onClick={recreatePaymentHandler}>
                    {t('try_again')}
                </button>
            </div>
        </CheckoutView>
    );
}

export default ErrorPage;
