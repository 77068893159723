import React from 'react';
import PropTypes from 'prop-types';

import styles from './Modal.module.scss';

const HeaderValue = ({
    children,
    className,
    isPrice,
}) => (
    <div className={`${styles.headerValue} ${isPrice && styles.headerValuePrice} ${className}`}>
        {children}
    </div>
);

HeaderValue.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isPrice: PropTypes.bool,
};

HeaderValue.defaultProps = {
    children: null,
    className: '',
    isPrice: false,
};

export default HeaderValue;
