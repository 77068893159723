import { captureException } from '@sentry/browser';
import { createAction } from 'redux-actions';

import { getCheckoutClient } from '../../services/api-client';

export const FETCH_PAYMENT_METHODS_REQUEST = 'payment/FETCH_PAYMENT_METHODS_REQUEST';
export const FETCH_PAYMENT_METHODS_SUCCESS = 'payment/FETCH_PAYMENT_METHODS_SUCCESS';
export const FETCH_PAYMENT_METHODS_FAILED = 'payment/FETCH_PAYMENT_METHODS_FAILED';

const fetchPaymentMethodsRequest = createAction(FETCH_PAYMENT_METHODS_REQUEST);
const fetchPaymentMethodsSuccess = createAction(FETCH_PAYMENT_METHODS_SUCCESS);
const fetchPaymentMethodsFailed = createAction(FETCH_PAYMENT_METHODS_FAILED);

export const fetchPaymentMethods = (paymentRequestHash, filter) => async (dispatch) => {
    dispatch(fetchPaymentMethodsRequest());

    const client = getCheckoutClient();

    try {
        const { data } = await client.getPaymentRequestMethods(paymentRequestHash, filter);
        await dispatch(fetchPaymentMethodsSuccess(data));
    } catch (error) {
        captureException(error);
        await dispatch(fetchPaymentMethodsFailed());
        throw error;
    }
};
