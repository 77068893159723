import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { PaymentRequestMethod } from '@paysera/checkout-client';

import { useTranslation } from 'react-i18next';
import InformationBlock from '../../FullPageWrap/PaymentInformation/InformationBlock';
import Method from './Method';
import { selectPaymentMethod as selectPaymentMethodAction } from '../../../../actions/payment/select-method';

const MethodGroup = ({
    groupKey,
    methods,
}) => {
    const dispatch = useDispatch();
    const { selectPaymentMethod } = bindActionCreators({
        selectPaymentMethod: selectPaymentMethodAction,
    }, dispatch);
    const { t } = useTranslation('checkout-frontend');
    const handleClick = useCallback((method) => {
        selectPaymentMethod(method.getData().method_key);
    }, [selectPaymentMethod]);

    return (
        <div>
            <InformationBlock
                headerLabel={t(`payment_methods_category_${groupKey}`)}
                toggleAble
            >
                {methods.map(method => (
                    <Method
                        key={method.getData().method_key}
                        method={method}
                        onClick={handleClick}
                    />
                ))}
            </InformationBlock>
        </div>
    );
};

MethodGroup.propTypes = {
    groupKey: PropTypes.string.isRequired,
    methods: PropTypes.arrayOf(PropTypes.instanceOf(PaymentRequestMethod)).isRequired,
};

export default memo(MethodGroup);
