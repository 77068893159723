import React from 'react';
import PropTypes from 'prop-types';

import arrowRight from '../../../../../images/arrow-right.png';

import styles from './Header.module.scss';

const Header = ({
    label, value, image, onToggle, withToggle, isToggled,
}) => (
    <div
        className={`${styles.header} ${withToggle ? styles.toggleable : ''}`}
        onClick={onToggle}
    >
        { image && (
            <div className={`col-2 ${styles.image}`}>
                <img src={image} alt="" />
            </div>
        )}

        <div className={`col ${styles.label} ${!image ? styles.noIcon : ''}`}>
            {`${label} ${value || ''}`}
        </div>

        <div className={`col-2 ml-auto ${styles.toggle} ${isToggled ? styles.toggled : ''}`}>
            { withToggle && (<img src={arrowRight} alt="" />) }
        </div>
    </div>
);

Header.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    image: PropTypes.string,
    onToggle: PropTypes.func,
    withToggle: PropTypes.bool,
    isToggled: PropTypes.bool.isRequired,
};

Header.defaultProps = {
    value: null,
    onToggle: null,
    withToggle: false,
    image: '',
};

export default Header;
