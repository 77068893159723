import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals'; // eslint-disable-line
import './sentry-config';
import Root from './components/Root';
import configureStore, { history } from './store/configure-store';
import './styles/styles.global.scss';
import '../node_modules/paysera-style-components/components/spinner/component.less';

const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Root store={store} history={history} />,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
