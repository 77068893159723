import { captureException } from '@sentry/browser';

const fetchAction = (action, promise, prepareResult = ({ data }) => data) => async (dispatch) => {
    dispatch(action.request());
    try {
        const result = await promise();
        await dispatch(action.success(prepareResult(result)));
    } catch (error) {
        captureException(error);
        await dispatch(action.failed());
        throw error;
    }
};

export default fetchAction;
