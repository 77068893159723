import React from 'react';
import { useTranslation } from 'react-i18next';
import { generateExternalUrl } from '@paysera/external-router';

import styles from './NotFoundPage.module.scss';
import { getEmailLink } from './Common/utilities';

const NotFoundPage = () => {
    const { t, i18n } = useTranslation('checkout-frontend');
    const activeLanguage = i18n.language;
    const supportEmail = getEmailLink('support', activeLanguage);
    const payseraBankUrl = generateExternalUrl('paysera_login', activeLanguage);

    const homepageLink = {
        __html: t(
            'go_back_to_homepage_and_browse',
            {
                homepageLink:
                    `<a href=${payseraBankUrl}
                     >${t('go_back_to_homepage_and_browse_link_text')}</a>`,
            },
        ),
    };

    const supportEmailLink = {
        __html: t(
            'contact_us_by_email',
            {
                emailLink: `<a href='mailto:${supportEmail}'>${supportEmail}</a>`,
            },
        ),
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.header}>{t('page_does_not_exist')}</h1>
            <h4 className={styles.weAreSorryText}>{t('we_are_sorry_but_page_not_found')}</h4>
            <h5>{t('page_not_found_reason')}</h5>
            <h4 className={styles.solutionText}>{t('solution')}</h4>
            <ul>
                <li>{t('check_if_url_is_correct')}</li>
                <li dangerouslySetInnerHTML={homepageLink} />
                {
                    supportEmail && (
                        <li dangerouslySetInnerHTML={supportEmailLink} />
                    )
                }
            </ul>
            <div>
                <h4>
                    <a href={payseraBankUrl}>{t('return_to_homepage')}</a>
                </h4>
            </div>
        </div>
    );
};

export default NotFoundPage;
