import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import footerLogoImage from '../../../images/paysera-logo-small.png';

import styles from './Modal.module.scss';

const ModalFooter = () => {
    const { t } = useTranslation('checkout-frontend');

    return (
        <div className={styles.modalFooter}>
            <div className={styles.footerText}>
                <img src={footerLogoImage} alt="" />
                <span>{t('footer_text')}</span>
            </div>
        </div>
    );
};

export default memo(ModalFooter);
