import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getCurrentHostUrl } from '../../services/host-url-provider';

import styles from './Status.module.scss';

const Status = ({ status }) => {
    const { t } = useTranslation('checkout-frontend');
    useEffect(() => {
        const PayseraAcrossWindows = require('paysera-across-windows');

        const childWindow = new PayseraAcrossWindows.Child(window, getCurrentHostUrl());

        childWindow.on(PayseraAcrossWindows.Event.MESSAGE, (message) => {
            if (message.method === 'close') {
                window.close();
            }
        });

        childWindow.sendMessageToParent({
            method: 'response',
            params: {
                status,
            },
        });
    }, [status]);

    return (status === 'success' ? (
        <div className={`${styles.message} ${styles.success}`}>
            <div>{t('secure_3d_success')}</div>
            <div>{t('window_will_close_automatically')}</div>
        </div>
    ) : (
        <div className={`${styles.message} ${styles.failed}`}>
            <div>{t('secure_3d_failed')}</div>
            <div>{t('window_will_close_automatically')}</div>
        </div>
    ));
};

Status.propTypes = {
    status: PropTypes.string.isRequired,
};

export default Status;
