import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import DebounceLoader from '../DebounceLoader';
import CreditCardWizard from '../CreditCardWizard/CreditCardWizard';
import { startPayment as startPaymentAction } from '../../../actions/payment/start-payment';
import { getCurrentRequest } from '../../../selectors/payment/get-current-request';
import { getStartedPayment } from '../../../selectors/payment/get-started-payment';
import { isLoading as isLoadingSelector } from '../../../selectors/is-loading';
import { usePaymentStatusRedirect } from '../../../hooks/usePaymentStatusRedirect';
import { getCurrentLocale } from '../../../selectors/get-current-locale';

const StartPayment = () => {
    const dispatch = useDispatch();
    const { startPayment } = bindActionCreators(
        {
            startPayment: startPaymentAction,
        },
        dispatch,
    );
    const startedPayment = useSelector(state => getStartedPayment(state));
    const paymentRequest = useSelector(state => getCurrentRequest(state));
    const currentLocale = useSelector(state => getCurrentLocale(state));
    const isLoading = useSelector(state => isLoadingSelector(state, 'start_payment'));

    const { redirectToError } = usePaymentStatusRedirect();

    useEffect(() => {
        startPayment(
            paymentRequest.getId(),
            paymentRequest.getMethodKey(),
            currentLocale,
        );
    }, [currentLocale]);

    const renderContent = () => {
        if (startedPayment) {
            const paymentFlowType = startedPayment.getType();

            switch (paymentFlowType) {
                case 'iframe':
                    return (
                        <CreditCardWizard
                            paymentRequest={paymentRequest}
                            iframeUrl={startedPayment.getUrl()}
                        />
                    );
                case 'redirect':
                    window.location = startedPayment.getUrl();
                    break;
                default:
                    redirectToError(paymentRequest.getId());
            }
        }

        return <div />;
    };

    const content = isLoading ? <div /> : renderContent();

    return (
        <DebounceLoader loaded={!isLoading}>
            {content}
        </DebounceLoader>
    );
};

export default StartPayment;
