import { createRequest, ClientWrapper } from '@paysera/http-client-common';

import Business from '../entity/Business';
import BusinessFilter from '../entity/BusinessFilter';
import BusinessResult from '../entity/BusinessResult';
import Checkup from '../entity/Checkup';
import CheckupComment from '../entity/CheckupComment';
import CheckupCommentResult from '../entity/CheckupCommentResult';
import Contact from '../entity/Contact';
import ContactFilter from '../entity/ContactFilter';
import ContactResult from '../entity/ContactResult';
import EmailReceiver from '../entity/EmailReceiver';
import EmailReceiverResult from '../entity/EmailReceiverResult';
import Logo from '../entity/Logo';
import { Filter } from '@paysera/http-client-common';
import SmsReceiver from '../entity/SmsReceiver';
import SmsReceiverResult from '../entity/SmsReceiverResult';

class BusinessClient {
    /**
     * @param {ClientWrapper} client
     */
    constructor(client) {
        this.client = client;
    }

    /**
     * Suspend business
     * PUT /businesses/{businessHash}/suspend
     *
     * @param {string} businessHash
     * @return {Promise.<Business>}
     */
    suspendBusiness(businessHash) {
        const request = createRequest(
            'PUT',
            `businesses/${encodeURIComponent(businessHash)}/suspend`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => new Business(data));
    }

    /**
     * Block business
     * PUT /businesses/{businessHash}/block
     *
     * @param {string} businessHash
     * @return {Promise.<Business>}
     */
    blockBusiness(businessHash) {
        const request = createRequest(
            'PUT',
            `businesses/${encodeURIComponent(businessHash)}/block`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => new Business(data));
    }

    /**
     * Activate business
     * PUT /businesses/{businessHash}/activate
     *
     * @param {string} businessHash
     * @return {Promise.<Business>}
     */
    activateBusiness(businessHash) {
        const request = createRequest(
            'PUT',
            `businesses/${encodeURIComponent(businessHash)}/activate`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => new Business(data));
    }

    /**
     * Get business logo
     * GET /businesses/{businessHash}/logo
     *
     * @param {string} businessHash
     * @return {Promise.<Logo>}
     */
    getBusinessLogo(businessHash) {
        const request = createRequest(
            'GET',
            `businesses/${encodeURIComponent(businessHash)}/logo`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => new Logo(data));
    }
    /**
     * Update business logo
     * PUT /businesses/{businessHash}/logo
     *
     * @param {string} businessHash
     * @return {Promise.<Logo>}
     */
    updateBusinessLogo(businessHash) {
        const request = createRequest(
            'PUT',
            `businesses/${encodeURIComponent(businessHash)}/logo`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => new Logo(data));
    }
    /**
     * Delete business logo
     * DELETE /businesses/{businessHash}/logo
     *
     * @param {string} businessHash
     * @return {Promise.<null>}
     */
    deleteBusinessLogo(businessHash) {
        const request = createRequest(
            'DELETE',
            `businesses/${encodeURIComponent(businessHash)}/logo`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => null);
    }

    /**
     * Get business sms receivers
     * GET /businesses/{businessHash}/sms-receivers
     *
     * @param {string} businessHash
     * @return {Promise.<SmsReceiverResult>}
     */
    getBusinessSmsReceivers(businessHash) {
        const request = createRequest(
            'GET',
            `businesses/${encodeURIComponent(businessHash)}/sms-receivers`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => new SmsReceiverResult(data, 'items'));
    }
    /**
     * Create business sms receiver
     * POST /businesses/{businessHash}/sms-receivers
     *
     * @param {string} businessHash
     * @param {SmsReceiver} smsReceiver
     * @return {Promise.<SmsReceiver>}
     */
    createBusinessSmsReceiver(businessHash, smsReceiver) {
        const request = createRequest(
            'POST',
            `businesses/${encodeURIComponent(businessHash)}/sms-receivers`,
            smsReceiver,
        );

        return this.client
            .performRequest(request)
            .then(data => new SmsReceiver(data));
    }

    /**
     * Get business email receivers
     * GET /businesses/{businessHash}/email-receivers
     *
     * @param {string} businessHash
     * @return {Promise.<EmailReceiverResult>}
     */
    getBusinessEmailReceivers(businessHash) {
        const request = createRequest(
            'GET',
            `businesses/${encodeURIComponent(businessHash)}/email-receivers`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => new EmailReceiverResult(data, 'items'));
    }
    /**
     * Create email receiver
     * POST /businesses/{businessHash}/email-receivers
     *
     * @param {string} businessHash
     * @param {EmailReceiver} emailReceiver
     * @return {Promise.<EmailReceiver>}
     */
    createBusinessEmailReceiver(businessHash, emailReceiver) {
        const request = createRequest(
            'POST',
            `businesses/${encodeURIComponent(businessHash)}/email-receivers`,
            emailReceiver,
        );

        return this.client
            .performRequest(request)
            .then(data => new EmailReceiver(data));
    }

    /**
     * Get business contacts
     * GET /businesses/{businessHash}/contacts
     *
     * @param {string} businessHash
     * @return {Promise.<ContactResult>}
     */
    getBusinessContacts(businessHash) {
        const request = createRequest(
            'GET',
            `businesses/${encodeURIComponent(businessHash)}/contacts`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => new ContactResult(data, 'items'));
    }
    /**
     * Create business contact
     * POST /businesses/{businessHash}/contacts
     *
     * @param {string} businessHash
     * @param {Contact} contact
     * @return {Promise.<Contact>}
     */
    createBusinessContact(businessHash, contact) {
        const request = createRequest(
            'POST',
            `businesses/${encodeURIComponent(businessHash)}/contacts`,
            contact,
        );

        return this.client
            .performRequest(request)
            .then(data => new Contact(data));
    }

    /**
     * Get next checkup information
     * GET /businesses/{businessHash}/checkup
     *
     * @param {string} businessHash
     * @return {Promise.<Checkup>}
     */
    getBusinessCheckup(businessHash) {
        const request = createRequest(
            'GET',
            `businesses/${encodeURIComponent(businessHash)}/checkup`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => new Checkup(data));
    }
    /**
     * Update next checkup information
     * POST /businesses/{businessHash}/checkup
     *
     * @param {string} businessHash
     * @param {Checkup} checkup
     * @return {Promise.<Checkup>}
     */
    createBusinessCheckup(businessHash, checkup) {
        const request = createRequest(
            'POST',
            `businesses/${encodeURIComponent(businessHash)}/checkup`,
            checkup,
        );

        return this.client
            .performRequest(request)
            .then(data => new Checkup(data));
    }
    /**
     * Delete next checkup
     * DELETE /businesses/{businessHash}/checkup
     *
     * @param {string} businessHash
     * @return {Promise.<null>}
     */
    deleteBusinessCheckup(businessHash) {
        const request = createRequest(
            'DELETE',
            `businesses/${encodeURIComponent(businessHash)}/checkup`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => null);
    }

    /**
     * Standard SQL-style Result filtering
     * GET /businesses/{businessHash}/checkup-comments
     *
     * @param {string} businessHash
     * @param {Filter} filter
     * @return {Promise.<CheckupCommentResult>}
     */
    getBusinessCheckupComments(businessHash, filter) {
        const request = createRequest(
            'GET',
            `businesses/${encodeURIComponent(businessHash)}/checkup-comments`,
            filter,
        );

        return this.client
            .performRequest(request)
            .then(data => new CheckupCommentResult(data, 'items'));
    }
    /**
     * Create checkup comment
     * POST /businesses/{businessHash}/checkup-comments
     *
     * @param {string} businessHash
     * @param {CheckupComment} checkupComment
     * @return {Promise.<CheckupComment>}
     */
    createBusinessCheckupComment(businessHash, checkupComment) {
        const request = createRequest(
            'POST',
            `businesses/${encodeURIComponent(businessHash)}/checkup-comments`,
            checkupComment,
        );

        return this.client
            .performRequest(request)
            .then(data => new CheckupComment(data));
    }

    /**
     * Get business item
     * GET /businesses/{businessHash}
     *
     * @param {string} businessHash
     * @return {Promise.<Business>}
     */
    getBusiness(businessHash) {
        const request = createRequest(
            'GET',
            `businesses/${encodeURIComponent(businessHash)}`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => new Business(data));
    }
    /**
     * Update business
     * PUT /businesses/{businessHash}
     *
     * @param {string} businessHash
     * @param {Business} business
     * @return {Promise.<Business>}
     */
    updateBusiness(businessHash, business) {
        const request = createRequest(
            'PUT',
            `businesses/${encodeURIComponent(businessHash)}`,
            business,
        );

        return this.client
            .performRequest(request)
            .then(data => new Business(data));
    }
    /**
     * Delete business
     * DELETE /businesses/{businessHash}
     *
     * @param {string} businessHash
     * @return {Promise.<null>}
     */
    deleteBusiness(businessHash) {
        const request = createRequest(
            'DELETE',
            `businesses/${encodeURIComponent(businessHash)}`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => null);
    }

    /**
     * Standard SQL-style Result filtering
     * GET /businesses
     *
     * @param {BusinessFilter} businessFilter
     * @return {Promise.<BusinessResult>}
     */
    getBusinesses(businessFilter) {
        const request = createRequest(
            'GET',
            `businesses`,
            businessFilter,
        );

        return this.client
            .performRequest(request)
            .then(data => new BusinessResult(data, 'items'));
    }
    /**
     * Create business
     * POST /businesses
     *
     * @param {Business} business
     * @return {Promise.<Business>}
     */
    createBusiness(business) {
        const request = createRequest(
            'POST',
            `businesses`,
            business,
        );

        return this.client
            .performRequest(request)
            .then(data => new Business(data));
    }

    /**
     * Standard SQL-style Result filtering
     * GET /contacts/resolve
     *
     * @param {ContactFilter} contactFilter
     * @return {Promise.<Contact>}
     */
    getContactResolve(contactFilter) {
        const request = createRequest(
            'GET',
            `contacts/resolve`,
            contactFilter,
        );

        return this.client
            .performRequest(request)
            .then(data => new Contact(data));
    }

    /**
     * Update contact
     * PUT /contacts/{id}
     *
     * @param {string} id
     * @param {Contact} contact
     * @return {Promise.<Contact>}
     */
    updateContact(id, contact) {
        const request = createRequest(
            'PUT',
            `contacts/${encodeURIComponent(id)}`,
            contact,
        );

        return this.client
            .performRequest(request)
            .then(data => new Contact(data));
    }
    /**
     * Delete contact
     * DELETE /contacts/{id}
     *
     * @param {string} id
     * @return {Promise.<null>}
     */
    deleteContact(id) {
        const request = createRequest(
            'DELETE',
            `contacts/${encodeURIComponent(id)}`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => null);
    }

    /**
     * Standard SQL-style Result filtering
     * GET /contacts
     *
     * @param {ContactFilter} contactFilter
     * @return {Promise.<ContactResult>}
     */
    getContacts(contactFilter) {
        const request = createRequest(
            'GET',
            `contacts`,
            contactFilter,
        );

        return this.client
            .performRequest(request)
            .then(data => new ContactResult(data, 'items'));
    }

    /**
     * Delete email receiver
     * DELETE /email-receivers/{id}
     *
     * @param {string} id
     * @return {Promise.<null>}
     */
    deleteEmailReceiver(id) {
        const request = createRequest(
            'DELETE',
            `email-receivers/${encodeURIComponent(id)}`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => null);
    }


    /**
     * Delete SMS receiver
     * DELETE /sms-receivers/{id}
     *
     * @param {string} id
     * @return {Promise.<null>}
     */
    deleteSmsReceiver(id) {
        const request = createRequest(
            'DELETE',
            `sms-receivers/${encodeURIComponent(id)}`,
            null,
        );

        return this.client
            .performRequest(request)
            .then(data => null);
    }


}

export default BusinessClient;
