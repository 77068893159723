import { captureException } from '@sentry/browser';
import { createAction } from 'redux-actions';
import { PaymentPreference } from '@paysera/checkout-client';

import { getCheckoutClient } from '../../services/api-client';

export const START_PAYMENT_REQUEST = 'payment/START_PAYMENT_REQUEST';
export const START_PAYMENT_SUCCESS = 'payment/START_PAYMENT_SUCCESS';
export const START_PAYMENT_FAILED = 'payment/START_PAYMENT_FAILED';

const startPaymentRequest = createAction(START_PAYMENT_REQUEST);
const startPaymentSuccess = createAction(START_PAYMENT_SUCCESS);
const startPaymentFailed = createAction(START_PAYMENT_FAILED);

export const startPayment = (
    paymentRequestHash,
    methodKey,
    currentLocale,
) => async (dispatch) => {
    dispatch(startPaymentRequest());

    const client = getCheckoutClient();

    const paymentPreference = new PaymentPreference();
    paymentPreference.setMethodKey(methodKey);
    paymentPreference.setLocale(currentLocale);

    try {
        const { data } = await client.processPaymentRequestPayment(paymentRequestHash, {
            ...paymentPreference.getData(),
            response_version: 2, // temporary
        });
        await dispatch(startPaymentSuccess(data));
    } catch (error) {
        captureException(error);
        await dispatch(startPaymentFailed());
    }
};
