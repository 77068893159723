import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import styles from './SimpleLoader.module.scss';

export function SimpleLoader() {
    return (
        <div className={styles.wrapper}>
            <FontAwesomeIcon icon={faSpinner} size='2x' pulse />
        </div>
    );
}
