import isEmpty from 'lodash/isEmpty';
import { Country } from '@paysera/country-client';

export const getCountriesState = ({ countries }) => countries;
export const getAllCountries = (state) => {
    const countries = getCountriesState(state).all;
    if (isEmpty(countries)) {
        return [];
    }
    return countries.map(country => new Country(country));
};
export const getCountriesLocale = state => getCountriesState(state).locale;
