import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Header from './InformationBlock/Header';

const InformationBlock = ({
    children,
    headerLabel,
    headerValue,
    headerImage,
    toggleAble,
}) => {
    const [opened, setOpened] = useState(true);

    const onToggleClicked = () => {
        if (toggleAble) {
            setOpened(!opened);
        }
    };

    return (
        <div className='information-wrap'>
            <Header
                label={headerLabel}
                value={headerValue}
                image={headerImage}
                isToggled={opened}
                onToggle={onToggleClicked}
                withToggle={toggleAble}
            />

            {opened && children}
        </div>
    );
};

InformationBlock.propTypes = {
    children: PropTypes.node.isRequired,
    headerLabel: PropTypes.string.isRequired,
    headerValue: PropTypes.string,
    headerImage: PropTypes.string,
    toggleAble: PropTypes.bool,
};

InformationBlock.defaultProps = {
    toggleAble: false,
    headerImage: '',
    headerValue: null,
};

export default InformationBlock;
