import { captureException } from '@sentry/browser';
import { createTranslationProxyClient } from '../../client/public-translation-proxy-client';

import defaultTranslations from '../../../translations/checkout-frontend.en.json';

export default async (language, namespace, callback) => {
    const translationProxyClient = createTranslationProxyClient();

    const domain = namespace;

    if (domain === 'translation') {
        // empty translations for default domain
        callback(null, {});
        return;
    }

    try {
        const listOfDomains = Array.isArray(domain) ? domain : [domain];
        const translations = listOfDomains.map(domainItem => new Promise((resolve) => {
            translationProxyClient.getTranslationsForDomainByLanguage(
                domainItem,
                language,
            ).then(
                response => resolve(response.data),
            ).catch(
                (error) => {
                    captureException(error);
                    callback(null, {});
                },
            );
        }));

        const mergedTranslations = (await Promise.all(translations))
            .reduce(
                (previousValue, currentValue) => ({ ...previousValue, ...currentValue }),
                defaultTranslations,
            );

        callback(null, mergedTranslations);
    } catch (error) {
        captureException(error);
        callback(null, {});
    }
};
