import React from 'react';
import PropTypes from 'prop-types';
import { BigNumber } from 'bignumber.js';

const InlinePrice = (props) => {
    const { amount, currency, className } = props;

    const formattedAmount = BigNumber(amount).toFixed(2);

    return (
        <span className={className}>
            {`${formattedAmount} ${currency}`}
        </span>
    );
};

InlinePrice.propTypes = {
    amount: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    className: PropTypes.string,
};

InlinePrice.defaultProps = {
    className: null,
};

export default InlinePrice;
