import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Child } from 'paysera-across-windows';

import { ICON_COLLECTION } from '../../../ui/Icon/icon-list';
import { Icon } from '../../../ui/Icon/Icon';

import styles from './Success.module.scss';

const Success = ({ successUrl, isModal }) => {
    const { t } = useTranslation('checkout-frontend');
    const [timeLeft, setTimeLeft] = useState(5);

    const createChildWindow = () => {
        if (typeof window.childWindow === 'undefined') {
            window.childWindow = new Child(window, '*');
        }
    };

    const onSuccessWindowClosed = () => {
        if (isModal) {
            createChildWindow();

            window.childWindow.sendMessageToParent({
                method: 'success',
                params: {},
            });

            window.childWindow.sendMessageToParent({
                method: 'close',
                params: {},
            });

            return;
        }

        window.location.href = successUrl;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (onSuccessWindowClosed === null) {
                clearInterval(interval);
                return;
            }

            setTimeLeft((prevTimeLeft) => {
                if (prevTimeLeft > 1) {
                    return prevTimeLeft - 1;
                }
                clearInterval(interval);
                onSuccessWindowClosed();
                return null;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [onSuccessWindowClosed]);

    return (
        <div className={styles.successWrap}>
            <div className={styles.successHeader}>
                <Icon
                    iconComponent={ICON_COLLECTION.success}
                    className={styles.successIcon}
                />
                <p className={styles.successMessage}>{t('success_message')}</p>
            </div>

            <p className={styles.timerWrap}>
                <span>{`${t('success_redirect_to_shop_in')} `}</span>
                {t('left_seconds', { seconds: timeLeft })}
            </p>

            <div>
                <button
                    type="button"
                    className={styles.closeButton}
                    onClick={onSuccessWindowClosed}
                >
                    <span>{t('close')}</span>
                    <Icon
                        iconComponent={ICON_COLLECTION.close}
                        className={styles.closeIcon}
                    />
                </button>
            </div>
        </div>
    );
};

Success.propTypes = {
    successUrl: PropTypes.string.isRequired,
    isModal: PropTypes.bool,
};

Success.defaultProps = {
    isModal: false,
};

export default Success;
