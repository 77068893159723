import { captureException } from '@sentry/browser';
import { createAction } from 'redux-actions';
import { getBusinessClient } from '../../services/api-client';

export const FETCH_BUSINESS_REQUEST = 'business/FETCH_BUSINESS_REQUEST';
export const FETCH_BUSINESS_SUCCESS = 'business/FETCH_BUSINESS_SUCCESS';
export const FETCH_BUSINESS_FAILED = 'business/FETCH_BUSINESS_FAILED';

const fetchBusinessRequest = createAction(FETCH_BUSINESS_REQUEST);
const fetchBusinessSuccess = createAction(FETCH_BUSINESS_SUCCESS);
const fetchBusinessFailed = createAction(FETCH_BUSINESS_FAILED);

export const fetchBusiness = hash => async (dispatch) => {
    dispatch(fetchBusinessRequest());

    const client = getBusinessClient();

    try {
        const { data } = await client.getBusiness(hash);
        await dispatch(fetchBusinessSuccess(data));
    } catch (error) {
        captureException(error);
        await dispatch(fetchBusinessFailed());
        throw error;
    }
};
