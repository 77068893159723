import React from 'react';
import PropTypes from 'prop-types';

import blueArrowLeft from '../../../images/blue-arrow-left.png';
import styles from './FullPageContentWithPaymentInformation.module.scss';

const CancelUrlBlock = ({ url, label }) => (
    <div className="container" key="cancelUrlBlock">
        <a href={url}>
            <img src={blueArrowLeft} alt="" />
            <span className={styles.cancelUrlText}>{label}</span>
        </a>
    </div>
);

CancelUrlBlock.propTypes = {
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default CancelUrlBlock;
