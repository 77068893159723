import Country from './Country';
import { Result } from '@paysera/http-client-common';

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["createItem"] }] */
class CountriesResult extends Result {
    /**
     * @param {Array} data
     * @returns {Country}
     */
    createItem(data) {
        return new Country(data);
    }
}

export default CountriesResult;
