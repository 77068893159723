import { useNavigate } from 'react-router-dom';

export const usePaymentStatusRedirect = () => {
    const navigate = useNavigate();

    const redirectToSuccess = (paymentRequestHash) => {
        navigate(`/payment-request/${paymentRequestHash}/success`);
    };

    const redirectToError = (paymentRequestHash) => {
        navigate(`/payment-request/${paymentRequestHash}/error`);
    };

    const redirectToHome = (paymentRequestHash) => {
        navigate(`/payment-request/${paymentRequestHash}`);
    };

    return { redirectToSuccess, redirectToError, redirectToHome };
};
