import config from '../../config';
import { getLocalesList } from '../../components/Common/utilities';

export const normalizeLocale = (locale) => {
    const supportedLocales = getLocalesList();

    if (typeof locale === 'string' && supportedLocales.includes(locale.toLowerCase())) {
        return locale.toLowerCase();
    }

    return config.defaultLocale;
};
