import { handleActions } from 'redux-actions';

import { FETCH_PAYMENT_REQUEST_SUCCESS } from '../../actions/payment/fetch-payment-request';
import { SELECT_METHOD } from '../../actions/payment/select-method';

export default handleActions({
    [FETCH_PAYMENT_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        ...payload,
    }),
    [SELECT_METHOD]: (state, { payload }) => ({
        ...state,
        method_key: payload,
    }),
}, {});
